import { useState, useEffect, useContext, useMemo } from 'react';
import styles from './trending-posts.module.scss';
import DestinationContext from '../../contexts/destination';
import { useUnitTracking } from '../../hooks/analytics/useUnitTracking';

const getTimeAgo = (timestamp) => {
  const date = new Date(timestamp);
  const now = new Date();
  const diffInSeconds = Math.floor((now - date) / 1000);
  const units = [
    { label: 'year', seconds: 31536000 },
    { label: 'month', seconds: 2592000 },
    { label: 'week', seconds: 604800 },
    { label: 'day', seconds: 86400 },
    { label: 'hour', seconds: 3600 },
    { label: 'minute', seconds: 60 },
  ];

  for (const unit of units) {
    const interval = Math.floor(diffInSeconds / unit.seconds);
    if (interval >= 1) {
      return `${interval} ${unit.label}${interval > 1 ? 's' : ''} ago`;
    }
  }
  return 'Just now';
};

const TrendingProductCard = ({ article, index }) => {
  const unitTrackingData = useMemo(
    () => ({
      unit_name: 'trending_posts',
      unit_type: 'buzz_bottom',
      item_type: 'card',
      item_name: article.id,
      position_in_unit: index + 1,
      target_content_url: article.url,
      target_content_type: 'buzz',
      target_content_id: article.id,
      data_source_name: 'recsys_api',
      eventCategory: 'list:bottom-feed',
    }),
    [article, index]
  );

  const unitEl = useUnitTracking(unitTrackingData, false, false);

  return (
    <a
      href={`${article.url}?origin=btrend`}
      className={styles.link}
      ref={unitEl}
    >
      <div className={styles.cardWrapper}>
        <div className={styles.imageWrapper}>
          <div className={styles.number}>{index + 1}</div>
          <img
            src={article.image}
            alt={article.title}
            className={styles.image}
          />
        </div>
        <div className={styles.timeAgo}>{getTimeAgo(article.created_at)}</div>
        <div className={styles.category}>
          {article.category === 'TVAndMovies' ? 'TV & MOVIES' : article.category}
        </div>
        <h2 className={styles.title}>{article.name}</h2>
      </div>
    </a>
  );
};

const TrendingProducts = () => {
  const { base_url } = useContext(DestinationContext);
  const [articles, setArticles] = useState([]);

  useEffect(() => {
    const controller = new AbortController();
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${base_url}/site-component/v1/en-us/trending`,
          { signal: controller.signal }
        );
        const data = await response.json();
        setArticles(data.results || []);
      } catch (error) {
        if (!controller.signal.aborted) {
          console.error('Fetch error:', error);
        }
      }
    };

    fetchData();
    return () => controller.abort();
  }, [base_url]);

  if (!articles.length) {
    return null;
  }

  return (
    <div className={styles.trending}>
      <h1 className={styles.header}>Trending on BuzzFeed</h1>
      <div className={styles.grid}>
        {articles.slice(0, 10).map((article, index) => (
          <TrendingProductCard key={article.id} article={article} index={index} />
        ))}
      </div>
    </div>
  );
};

export default TrendingProducts;
