import { useContext, useState, useEffect } from 'react';
import ABeagleContext from '../../contexts/abeagle';
import NativeShareButton from '../NativeShareButton';
import ShareBar from '../ShareBar';
import styles from './share-bar-bottom.module.scss';
import { isNativeShareEligible } from '../../utils/sharing';
import { useTranslation } from '../../i18n';
import { isDiscussionPage } from '../../utils/isDiscussionPage';


const ShareBarBottom = ({
    buzz,
}) => {
    const { experiments, getExperimentValue } = useContext(ABeagleContext);
    const [mobileSharingVariant, setMobileSharingVariant] = useState(false);
    const [isMobileShareEnabled, setIsMobileShareEnabled] = useState(false); // Is able to native share on mobile device
    const { t } = useTranslation('common');

    useEffect(() => {
    if (experiments && experiments.loaded) {
        setMobileSharingVariant(
            getExperimentValue('XX-1600-shopping-subbuzz-sharing', {
                rejectErrors: false,
            })
        );
    }
    }, [experiments, getExperimentValue]);

    useEffect(() => {
        if (isNativeShareEligible()) {
            setIsMobileShareEnabled(true);
        }
    }, []);

    let type = 'standard';
    if (isDiscussionPage(buzz)) {
        type = 'discussion';
    } else if (buzz.isShopping) {
        type = 'shopping';
    } else if (buzz.isQuiz) {
        type = 'quiz';
    }

    const showBar =
        (['quiz', 'discussion'].includes(type) ||
        (type === 'shopping' && ['share_enabled_v1'].includes(mobileSharingVariant)))
        && !buzz.shouldHideBuzzSharing;

    const showPlatformShareButtons = showBar && !isMobileShareEnabled && type !== 'shopping';
    const showNativeShare = showBar && isMobileShareEnabled;

    const headingKeys = {
        standard: 'share_article',
        shopping: 'share_article',
        discussion: 'share_discussion',
        quiz: 'share_quiz',
    };

    return ((showPlatformShareButtons || showNativeShare) &&
        <div className={styles.shareBottom}>
            <h3 className={styles.shareCta}>
                {t(headingKeys[type])}
            </h3>
            <div className={styles.shareBar}>
                {showPlatformShareButtons &&
                    <ShareBar
                        buzz={buzz}
                        type="pageLevelOutlineThick"
                        position="bottom_share_bar"
                        />}
                {showNativeShare &&
                    <div className={styles.nativeShareButton}>
                        <NativeShareButton
                            variant="standard"
                            includeShortTitle
                            trackingData={{
                                unit_name: buzz.id,
                                unit_type: 'buzz_bottom',
                                subunit_type: 'component',
                                subunit_name: 'share_buttons'
                            }}
                        />
                    </div>
                }
            </div>
        </div>
    );
};

export default ShareBarBottom;