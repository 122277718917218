import React, { useContext, useMemo } from 'react';
import clsx from 'clsx';
import stylesShopping from './package-new-shopping.module.scss';
import stylesDefault from './package-new.module.scss';
import { usePackageTracking } from '../../hooks/analytics/usePackageTracking';
import { usePackage } from '../../hooks/usePackage';
import BuzzContext from '../../contexts/buzz';
import DestinationContext from '../../contexts/destination';
import { isInternalUrl } from '@buzzfeed/bf-utils/lib/internal-url';
import SectionTitle from '../SectionTitle';
import { CdnImage } from '@buzzfeed/react-components';
import { isNews } from '../../utils/isNews';
import {
  DesktopTopLeft,
  DesktopBottomLeft,
  DesktopBottomRight,
  DesktopTopRight,
  MobileBottomLeft,
  MobileBottomRight,
  MobileTopRight,
} from './package-new-svgs';

function NewPackage() {
  const buzz = useContext(BuzzContext);
  const packageData = usePackage({ buzz });
  const packageCategory = packageData?.category || '';

  const styles = buzz.isShopping ? stylesShopping : stylesDefault;

  if (!packageData) {
    return null;
  }

  return (
    <div className={styles.fullWidth}>
      { buzz.isShopping && <DecorativeSvgs styles={styles} />}
      <section className={clsx(styles.shoppingWrapper, isNews(buzz) && styles.bottomShoppingList)}>
        <SectionTitle className="new-shopping-package-title" id="new-shopping-package-title">
          Your New Favorite Thing
        </SectionTitle>
        <ul className={styles.gridNarrow}>
          {packageData.results.slice(0, 6).map((item, index) => (
            <PackageListItem key={item.id} item={item} index={index + 1} category={packageCategory} styles={styles} />
          ))}
        </ul>
      </section>
    </div>
  );
}

function DecorativeSvgs({ styles }) {
  return (
    <>
      <MobileBottomLeft className={styles.mobileBottomLeft} title="Shopping Bag" />
      <MobileBottomRight className={styles.mobileBottomRight} title="Money Signs" />
      <MobileTopRight className={styles.mobileTopRight} title="Money Signs" />
      <DesktopTopLeft className={styles.desktopTopLeft} title="Top Left Decoration" />
      <DesktopBottomLeft className={styles.desktopBottomLeft} title="Bottom Left Decoration" />
      <DesktopTopRight className={styles.desktopTopRight} title="Top Right Decoration" />
      <DesktopBottomRight className={styles.desktopBottomRight} title="Bottom Right Decoration" />
    </>
  );
}

function PackageItem({ item, index, category, styles, imageLayout = 'stacked', imageSize = 'big' }) {
  const { destination } = useContext(DestinationContext);
  const targetTracking = isInternalUrl(item.url)
    ? { target_content_type: 'buzz', target_content_id: item.id }
    : { target_content_url: item.url, target_content_type: 'url', target_content_id: item.url };

  const unitTrackingData = useMemo(() => ({
    ...item,
    item_name: item.id,
    item_type: index === 0 ? 'splash' : 'card',
    position_in_subunit: index,
    index,
    category,
    ...targetTracking,
    data_source_name: 'site_component_api',
  }), [item, index, category, targetTracking]);

  const unitEl = usePackageTracking(unitTrackingData);

  return (
    <article>
      <a href={item.url} ref={unitEl} className={clsx(styles[imageLayout], styles.shoppingItemLink, styles[destination])}>
        <div className={clsx(styles.imageWrap, styles[imageSize])}>
          <CdnImage src={item.image} alt={item.image_alt_text} />
        </div>
        <span className={styles.text}>{item.name}</span>
      </a>
    </article>
  );
}

function PackageListItem({ item, index, category, styles }) {
  return (
    <li>
      <PackageItem item={item} index={index} category={category} styles={styles} />
    </li>
  );
}

export default NewPackage;
