import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { i18n, useTranslation } from '../../i18n';
import { useShareTracking } from '../../hooks/analytics/useShareTracking';
import styles from './share-bar.module.scss';

import {
  ShareButton,
  useBreakpoint,
  withErrorBoundary,
} from '@buzzfeed/react-components';
import { withUtmTracking } from '@buzzfeed/site-tracking/shares';
import { getEligiblePlatforms } from '../../utils/sharing/index';
import { destinations } from '../../constants';
import PinterestOverlayShareButton from './PinterestOverlayShareButton';
import { decodeHtmlEntities } from '../../utils/decodeHtmlEntities';
import { captureException } from '@sentry/nextjs';

const translationKeys = {
  copy: {
    copyFailText: 'unable_to_copy_link',
    copySuccessText: 'link_copied',
    shareOnText: 'copy_link',
  },
  email: {
    shareOnText: 'email',
  },
  bluesky: {
    shareOnText: 'share_on_x',
  },
  facebook: {
    shareOnText: 'share_on_x',
  },
  lineapp: {
    shareOnText: 'share_on_x',
  },
  more: {
    shareOnText: 'more_sharing_options',
  },
  pinterest: {
    savePinText: 'save_pin',
    shareOnText: 'share_on_x',
  },
  sms: {
    shareOnText: 'share_on_x',
  },
  snapchat: {
    shareOnText: 'share_on_x',
  },
  twitter: {
    shareOnText: 'share_on_x',
  },
  vk: {
    shareOnText: 'share_on_x',
  },
  whatsapp: {
    shareOnText: 'share_on_x',
  },
};

export const displayNames = {
  bluesky: 'Bluesky',
  facebook: 'Facebook',
  pinterest: 'Pinterest',
  twitter: 'Twitter',
  vk: 'VK',
};

export function ShareBar({
  buzz,
  heading,
  children,
  position = '',
  platformOverrides = {},
  showLabel = false,
  type = 'pagelevel',
  url = '',
  className = '',
  subbuzzId = undefined,
  onLoaded = () => {},
}) {
  const { t } = useTranslation('common', { i18n });
  const isPageLevel = [
    'pagelevel',
    'pagelevelList',
    'pagelevelSticky',
    'pageLevelOutlineThick',
  ].includes(type);
  const mobileBreakpoint = isPageLevel ? '500px' : '51.9rem'; // page level break is based on bf_header_ui break
  const isMobile = useBreakpoint(`(max-width:${mobileBreakpoint})`);
  const createShareTrackingFn = useShareTracking(buzz, subbuzzId);
  const platforms = getEligiblePlatforms(type, buzz, isMobile, position);
  const [loaded, setLoaded] = useState(false);
  const destination = buzz.destination_name;

  useEffect(() => {
    setLoaded(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sharedProps = {
    className: `${styles.shareButton} ${styles[type] || ''} ${styles[
      position
    ] || ''}`,
    type,
    showLabel,
    /**
     *  For now ensures sharing the new version of bpage, along
     *  with its metadata as used in FB shares
     */
    url: url || buzz.canonical_url,
  };

  if (isPageLevel) {
    sharedProps.variant = isMobile ? 'small' : 'large'; // display type of share buttons
  } else if (type === 'subbuzz') {
    sharedProps.variant = 'outline';
  }

  if (type === 'pageLevelOutlineThick') {
    sharedProps.variant = 'outlineThick';
  }

  if (destination === 'buzzfeed_news') {
    sharedProps.variant = ['pagelevel', 'pageLevelOutlineThick'].includes(type) ? 'outlineMonochrome' : 'small';
  }

  /**
   * For subbuzzes that only have pinterest as the only share platform
   *  will use the overlay version of the button (Currently use case is
   *  on page views that are pinterest referred)
   */
  const usePinterestOverlayShare =
    platforms &&
    platforms.length === 1 &&
    platforms[0] === 'pinterest' &&
    type === 'subbuzz';

  const shareButtons = [];
  platforms.forEach((platform, index) => {
    if (buzz.shareData[platform] || platform === 'more') {
      const platformText = translationKeys[platform];

      const translatedText = {};
      Object.keys(platformText).forEach(key => {
        translatedText[key] = t(platformText[key], {
          name: displayNames[platform],
        });
      });

      const title = decodeHtmlEntities(buzz.shareData[platform].title);
      const platformOptions = {
        ...buzz.shareData[platform],
        ...translatedText,
        title,
      };
      if (platformOverrides && platformOverrides[platform]) {
        Object.keys(platformOverrides[platform]).forEach(key => {
          platformOptions[key] = platformOverrides[platform][key];
        });
      }

      if (platform === 'facebook') {
        platformOptions.fbAppId = destinations[destination].facebook_app_id;
      } else if (platform === 'email') {
        /**
         * emails on buzzfeed.com should also add link to BuzzFeed App; but need to parse out and handle
         *  share url
         */
        const emailUrl = withUtmTracking({
          shareUrl: sharedProps.url,
          platform,
        });
        platformOptions.body = `${emailUrl}\n\nGet the BuzzFeed App: https://bzfd.it/bfmobileapps`;
      }

      if (platform === 'pinterest' && usePinterestOverlayShare) {
        /**
         * For pinterest referred subbuzzes, use custom component, which
         *  includes all of ShareButton functionality, in addition to
         *  intersection (show/hide) logic and custom styling overrides
         */
        shareButtons.push(
          <PinterestOverlayShareButton
            platformOptions={platformOptions}
            {...sharedProps}
            key="pinterestShare"
          />
        );
      } else {
        // replace _ with -
        let sharePosition = position.replace(/_/g, '-');

        shareButtons.push(
          <li key={index}>
            <ShareButton
              platform={platform}
              platformOptions={platformOptions}
              tracking={createShareTrackingFn({
                platform,
                position: sharePosition,
              })}
              onLoaded={(element, shareUrl) => onLoaded({ element, index, platform, shareUrl })}
              {...sharedProps}
            />
          </li>
        );
      }
    }
  });

  return (
    <div
      role="group"
      aria-label={heading || 'Share'}
      className={
        usePinterestOverlayShare ? styles.shareBarContainer : className
      }
    >
      {heading && <h2 className={styles.heading}>{heading}</h2>}
      <ul
        className={`${styles.shareBar} ${styles[type] || ''} ${
          loaded ? styles.loaded : ''
        } ${styles[destination]}`}
      >
        {shareButtons}
        {children}
      </ul>
    </div>
  );
}

ShareBar.propTypes = {
  buzz: PropTypes.object,
  className: PropTypes.string,
  position: PropTypes.oneOf([
    'top_share_bar',
    'bottom_share_bar',
    'subbuzz',
    'quiz_results',
  ]),
  platformOverrides: PropTypes.object,
  showLabel: PropTypes.bool,
  type: PropTypes.oneOf([
    'pagelevel',
    'subbuzz',
    'pagelevelList',
    'pagelevelSticky',
    'pageLevelOutlineThick',
  ]),
  url: PropTypes.string,
};

export default withErrorBoundary(ShareBar, {
  onError: captureException,
});
