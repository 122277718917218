// import ShareBar from '../ShareBar';
import React, { useEffect, useState } from 'react';
import { Portal } from '@buzzfeed/react-components';
import { isNativeShareEligible } from '../../utils/sharing';
import NativeShareButton from '../NativeShareButton';
import CopyShareButton from './CopyShareButton';
import styles from './subbuzz-share.module.scss';

const SubbuzzShareButton = ({ buzzId, subbuzzId, canonicalUrl, location }) => {
  const [type, setType] = useState(null);

  useEffect(() => {
    setType(isNativeShareEligible() ? 'native' : 'copy');
  }, []);

  if (!type) {
    return null;
  }

  const trackingData = {
    unit_name: buzzId,
    unit_type: 'buzz_body',
    subunit_name: subbuzzId,
    subunit_type: 'subbuzz',
  };
  return (
    <div className={`${styles.subbuzzShare} ${styles[location]}`}>
      {type === 'native' ?
        <NativeShareButton subbuzzId={subbuzzId} trackingData={trackingData} variant='dark' /> :
        <CopyShareButton canonicalUrl={canonicalUrl} subbuzzId={subbuzzId} trackingData={trackingData} />
      }
    </div>
  );
}

export const SubbuzzShare = ({ buzz, subbuzzId, subbuzzRef, location }) => {
  const [shareContainer, setShareContainer] = useState(null);
  useEffect(() => {
    if (subbuzzId && subbuzzRef?.current) {
      // find raw data for the subbuzz
      const subbuzz = buzz.sub_buzzes.find((sb) => {
        return sb.id === subbuzzId;
      });
      if (subbuzz?.shareable) {
        const shareLocation = subbuzzRef.current.querySelector(`div[data-subbuzz-share="${subbuzzId}"]`);
        if (shareLocation) {
          setShareContainer(shareLocation);
        }
      }
    }
  }, [subbuzzId, subbuzzRef?.current]);

  if (
    buzz.shouldHideSubbuzzSharing ||
    buzz.shouldShowPostContentOnly ||
    buzz.destination_name !== 'buzzfeed'
  ) {
    return null;
  }

  if (shareContainer) {
    return (
      <Portal element={shareContainer} key={`subbuzz-share-${subbuzzId}`}>
        <SubbuzzShareButton
          buzzId={buzz.id}
          subbuzzId={subbuzzId}
          canonicalUrl={buzz.canonical_url}
          location={location}
        />
      </Portal>
    )
  }

  return null;
}

export default SubbuzzShare;
