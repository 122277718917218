import { ErrorBoundary, useBreakpoint, useIntersection } from '@buzzfeed/react-components';
import { captureException } from '@sentry/nextjs';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useRef, useState } from 'react';
import AdUnit from '../../Ads/AdUnit';
import CommentsWrapper from '../../CommentsWrapper';
import ContentBottom from '../../ContentBottom';
import DestinationContext from '../../../contexts/destination';
import { FlameIcon } from '../../Svgs/FlameIcon';
import { NativeShareIcon } from '../../Svgs/NativeShareIcon';
import NewsletterSignup from '../../NewsletterSignup/lazy';
import { SpeechBuble } from '../../Svgs/SpeechBuble';
import { BottomRecircList, RelatedRecircList } from '../../RecircList/variants';
import Sidebar from '../../Sidebar';
import TopicPageLink from '../../TopicPageLink';
import DiscussionCtaButton from '../../DiscussionCtaButton';
import DiscussionImage from '../../DiscussionImage';
import HeadlineByline from '../../Headline/components/HeadlineByline';
import layoutStyles from '../post.module.scss';
import ShareBarBottom from '../../ShareBarBottom';
import NativeShareButton from '../../NativeShareButton';
import { isNativeShareEligible } from '../../../utils/sharing';
import { trackClientContentAction } from '../../../hooks/analytics/client-event-tracking';
import { ShareBar } from '../../ShareBar';
import FloatingShareButton from '../../ActionBar/FloatingShareButton';

function DiscussionPost({ buzz }) {
  const ref = useRef(null);
  const { isIntersecting: isFloatingShareButtonEnd, setObservable: setFloatingShareButtonEnd } = useIntersection({ once: true });
  const { destination } = useContext(DestinationContext);
  const isMobile = useBreakpoint('(max-width:500px)'); // page level break is based on bf_header_ui break
  const [commentCount, setCommentCount] = useState(null);
  const [canShare, setCanShare] = useState();
  const [isMobileShareEnabled, setIsMobileShareEnabled] = useState(false); // Is able to native share on mobile device
  const showHeadline = false; // toDo: find a way to get data (title, link...) for the healine

  // The longform custom header will override the title.
  const { title, description } = buzz.longform_custom_header ?? buzz;
  const imageSubbuzzes = buzz.sub_buzzes?.filter(sb => sb.form === 'image');

  let topicPage = buzz.relatedTopicFeeds?.topicPageTagFeeds?.find((feed) =>
      feed.edition === buzz.country_code && feed.domain === 'bf') || null;
  if (!topicPage && buzz.relatedTopicFeeds?.remainingTagFeeds?.length) {
    topicPage = buzz.relatedTopicFeeds.remainingTagFeeds.find((feed) =>
      feed.edition === buzz.country_code && feed.domain === 'bf') || null;
  }

  useEffect(() => {
    const url = window.location.href;
    if (navigator?.share && navigator.canShare({ url })) {
      setCanShare(true);
    }
  }, []);

  const onShareClick = () => {
    trackClientContentAction(buzz,
      {
        action_type: 'share',
        action_value: 'native_share',
        item_type: 'button',
        item_name: 'native_share',
        unit_type: 'buzz_head',
        unit_name: buzz.id,
        subunit_type: 'component',
        subunit_name: 'discussion_question',
      }
    );
    const share = () => {
      navigator.share({
        title,
        url: window.location.href
      });
    };
    share();
  }

  useEffect(() => {
    if (isNativeShareEligible()) {
      setIsMobileShareEnabled(true);
    }
  }, []);

  let shareCta;
  if (isMobileShareEnabled) {
    shareCta =
      <div className={layoutStyles.discussionShareNewButton}>
        <NativeShareButton
          variant="standard_small"
          includeShortTitle
          trackingData={{
            unit_type: 'buzz_head',
            unit_name: buzz.id,
            subunit_type: 'component',
            subunit_name: 'discussion_question'
          }}
        />
      </div>;
  } else if (isMobile) {
    shareCta = (canShare &&
      <button onClick={onShareClick} className={layoutStyles.nativeShare}><NativeShareIcon title="Share" className={layoutStyles.nativeShareIcon}/></button>
    );
  } else { // desktop
    shareCta = <ShareBar buzz={buzz} type="pageLevelOutlineThick" position="top_share_bar" />;
  }

  return (
    <main
      id="buzz-content"
      className={`${layoutStyles.main} ${layoutStyles[destination]} ${layoutStyles.commentsMain} embed-content`}
      ref={ref}
    >
      <div
        className={`${layoutStyles.article} ${layoutStyles.default} ${layoutStyles[destination]} embed-post`}
      >
        <div className={layoutStyles.content}>
          {topicPage &&
            <TopicPageLink
              display_name={topicPage.display_name}
              position="top"
              slug={topicPage.slug}
            />}
          {showHeadline && <div className={layoutStyles.headlineContainer}>
            Shared on <FlameIcon className={layoutStyles.flameIcon} title="Harry Potter"/><a href="#" className={layoutStyles.flameTitle}>Harry Potter</a>
          </div>}
          <article className={layoutStyles.discussionTitleWrapper}>
            <HeadlineByline bylines={buzz.bylines} isAd={buzz.flags.ad === 1} buzz={buzz} />
            <h1 dangerouslySetInnerHTML={{ __html: title }} />
            <p dangerouslySetInnerHTML={{ __html: description }} />
            {(imageSubbuzzes.length > 0) && imageSubbuzzes.map((image, index) =>
              <DiscussionImage key={image.id} {...image} buzz={buzz} index={index} />
            )}
            <p className={layoutStyles.commentsAndShare}>
              {commentCount ? (
                <><SpeechBuble className={layoutStyles.commentsBubble} /><span className={layoutStyles.numberOfComments}>{commentCount}</span></>
              ) : (
                <DiscussionCtaButton />
              )}
              {shareCta}
            </p>
          </article>

          <ErrorBoundary onError={captureException}>
            <div className={layoutStyles.discussionCommentsWrapper} >
              <CommentsWrapper
                buzz={buzz}
                commentCount={commentCount}
                onCommentsLoaded={setCommentCount}
                firstPage={9}
                repliesFirstPage={2}
              />
            </div>
            {topicPage &&
              <TopicPageLink
                display_name={topicPage.display_name}
                position="bottom"
                slug={topicPage.slug}
              />}
            <div className={layoutStyles.discussionShare}>
              <ShareBarBottom buzz={buzz} />
              <FloatingShareButton isFloatingShareButtonEnd={isFloatingShareButtonEnd} />
            </div>
            <AdUnit
              type="promo-inline1"
              pixiedust={{
                unit_type: 'buzz_bottom',
                unit_name: buzz.id,
                position_in_unit: 0,
              }}
            />
            <ContentBottom buzz={buzz} />
            <div ref={setFloatingShareButtonEnd}></div>
            <RelatedRecircList />
            <NewsletterSignup />
            {isMobile && (
              <AdUnit
                type="bigstory"
                pixiedust={{
                  unit_type: 'buzz_bottom',
                  unit_name: buzz.id,
                  position_in_unit: 1,
                }}
              />
            )}
          </ErrorBoundary>
        </div>
        <Sidebar
          pixiedust={{
            unit_type: 'sidebar',
            unit_name: 'right',
            position_in_unit: 0,
          }}
        />
      </div>
      <ErrorBoundary onError={captureException}>
        <BottomRecircList className={layoutStyles.discussionBottomUnit} />
      </ErrorBoundary>
    </main>
  );
}

DiscussionPost.propTypes = {
  buzz: PropTypes.object.isRequired,
  badges: PropTypes.array,
};

export default DiscussionPost;
