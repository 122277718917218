import React, { useContext, useEffect, useState } from 'react';
import ABeagleContext from '../../contexts/abeagle';
import BuzzContext from '../../contexts/buzz';
import { adShieldScript } from '@buzzfeed/bf-utils';



function AdShieldScript() {
  const buzz = useContext(BuzzContext);
  const { getFeatureFlagValue } = useContext(ABeagleContext);
  const isEnabled = getFeatureFlagValue('RT-1559-AdShield-script-on-BFDC') || getFeatureFlagValue('RT-1559-AdShield-script-on-BFN');
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    if (isEnabled && !isLoaded) {
      setIsLoaded(true);
      adShieldScript.init({
        isShopping: buzz.isShopping,
        destination: buzz.destination_name
      });
    }
  }, [isEnabled]);

  return (
    <></>
  );
}

export default AdShieldScript;
