import { useEffect, useRef, useContext } from 'react';
import cookies from '@buzzfeed/bf-utils/lib/cookies';
import { trackFacebookAffiliateClick } from './facebook/client';
import { trackOutbrainAffiliateClick } from './outbrain/client';
import { trackPermutiveAffiliateClick } from './permutive/client';
import { trackAdsConversionEvent } from './google/adsConversion';
import { getElementData } from '@buzzfeed/bf-utils/lib/affiliate-link';
import { isAffiliateLink } from '@buzzfeed/react-components/lib/utils/affiliate';
import ABeagleContext from '../../contexts/abeagle';
import ConsentContext from '../../contexts/consent';

const AFFILIATE_MAPPINGS = {
  amazon: ['amazon'],
  target: ['target'],
  walmart: ['walmart',],
};

const updateAffiliateCookie = (affiliate = '') => {
  const cookieName = 'bf-affiliate';
  const affiliateLower = affiliate.toLowerCase();

  // Get existing cookie value
  const currentCookie = cookies.get(cookieName);

  let values = new Set();
  if (currentCookie) {
    values = new Set(currentCookie.split(','));
  }

  // Map affiliate to cookie value
  let cookieValue = Object.entries(AFFILIATE_MAPPINGS).find(([_, keywords]) =>
    keywords.some(keyword => affiliateLower.indexOf(keyword) !== -1)
  )?.[0] || 'other';

  // Add new value to set
  values.add(cookieValue);

  // Set cookie with updated values
  const cookieDomain = cookies.getBuzzfeedSubdomainOrWildcard(
    window.location.hostname
  );
  cookies.set({
    name: cookieName,
    value: Array.from(values).join(','),
    days: 365,
    domain: cookieDomain,
  });
};

const onAffiliateLinkClick = async ({
  subbuzzElement,
  element,
  buzz,
  isPermutiveEnabled,
}) => {
  if (element.hasAttribute('data-affiliate')) {
    const data = getElementData(element);
    const anchor = subbuzzElement
      ? subbuzzElement.querySelector('div.subbuzz-anchor')
      : null;
    const subbuzzId = anchor && anchor.id ? anchor.id : null;

    updateAffiliateCookie(data?.affiliate);

    trackFacebookAffiliateClick(buzz.destination_name, buzz, {
      section: buzz.classification.section,
      currency: data['price.currency'],
      contents: data.href,
      value: data['price.value'],
      linkId: data['link-id'],
      'subbuzz-id': subbuzzId,
    });
    if (isPermutiveEnabled) {
      trackPermutiveAffiliateClick(
        {
          affiliate: data.affiliate,
          campaign: data.campaign,
          href: data.href,
          name: data.name,
          price: {
            value: Number(parseFloat(data['price.value'] || 0).toFixed(2)),
            currency: data['price.currency'] || '',
          },
          redirectUrl: data.redirecturl,
          keywords:
            typeof data.keywords === 'string' ? data.keywords.split(',') : [],
          retailers:
            typeof data.retailers === 'string' ? data.retailers.split(',') : [],
          affiliateLinkId: data['link-id'],
        },
        buzz
      );
    }
    trackOutbrainAffiliateClick();
    trackAdsConversionEvent();
  }
};

export function useAffiliateLinkTracking({ buzz = {} }) {
  const ref = useRef(null);
  const { tracking: { consentValue, isConsentReady } } = useContext(ConsentContext);
  const { getFeatureFlagValue } = useContext(ABeagleContext);
  const isPermutiveEnabled = getFeatureFlagValue('ADSGROUP-442-permutive');

  useEffect(() => {
    const element = ref.current;
    // do not bind tracking if we don't have an element, tracking consent, or a/b test info back yet
    if (
      !element ||
      !isConsentReady ||
      (isConsentReady && !consentValue) ||
      isPermutiveEnabled === null
    ) {
      return;
    }
    element.querySelectorAll('a[href]').forEach(link => {
      if (!isAffiliateLink(link)) {
        return;
      }
      link.addEventListener('click', ev => {
        onAffiliateLinkClick({
          subbuzzElement: element,
          element: ev.target.closest('a'),
          buzz,
          isPermutiveEnabled,
        });
      });
    });
  }, [buzz, isConsentReady, consentValue, isPermutiveEnabled]);

  return ref;
}
