/**
 *  Promo Config
 *  this config defines the promos and under what conditions they show
 *  items will be evaluated in order so should be from most to least specific
 */

// Links to app stores. campaignId will be appended to these
export const StoreLinks = {
  buzzfeed: {
    'ios': 'https://apps.apple.com/app/apple-store/id352969997?pt=329394&&mt=8&ct=',
    'android': 'https://play.google.com/store/apps/details?id=com.buzzfeed.android&utm_campaign=',
    'desktop': 'https://www.buzzfeed.com/app?c=',
  },
  tasty : {
    'ios': 'https://apps.apple.com/us/app/tasty-recipes-cooking-videos/id1217456898?pt=xxxx&&mt=8&ct=',
    'android': 'https://play.google.com/store/apps/details?id=com.buzzfeed.tasty&utm_source=buzzfeed&utm_medium=web&utm_campaign=',
    'desktop': 'https://www.tasty.co/download?c=',
  },
}

export const PromoData = [
  {
    name: 'tasty',
    enabled: true,
    match: buzz => buzz.category === 'Tasty',
    content: {
      promoType: 'button',
      btnText: '7,500+ recipes, 1 free app',
      campaignId: 'bftasty',
      storeLinks: StoreLinks.tasty,
      unit_name: 'tasty',
      subunit_name: 'tasty_mobile_app_promo',
    }
  },
  {
    name: 'facebook',
    enabled: false,
    match: (buzz, referrer) => referrer == 'facebook',
    content: {
      promoType: 'card',
      titleText: 'Life is better in the app!',
      descText: 'What’s trending, funny tweets, quizzes, games, and more.',
      btnText: 'Get the BuzzFeed app',
      closeText: 'Not now',
      campaignId: 'post-fb',
      storeLinks: StoreLinks.buzzfeed,
    }
  },
  {
    name: 'quiz',
    enabled: true,
    match: buzz => buzz.isQuiz,
    content: {
      promoType: 'button',
      btnText: 'More Quizzes in the App!',
      campaignId: 'quiz',
      storeLinks: StoreLinks.buzzfeed,
    }
  },
  {
    name: 'default',
    enabled: false,
    match: buzz => !buzz.isQuiz,
    content: {
      promoType: 'button',
      btnText: 'Get the App!',
      campaignId: 'post',
      storeLinks: StoreLinks.buzzfeed,
    }
  },
  {
    // special promo activated on quizzes after completion
    name: 'quiz-complete',
    enabled: true,
    match: buzz => buzz.isQuiz,
    content: {
      promoType: 'card',
      titleText: 'Quizzes are better in the app!',
      descText: 'What’s trending, funny tweets, quizzes, games, and more.',
      btnText: 'Get the BuzzFeed app',
      closeText: 'Not now',
      campaignId: 'quiz-complete',
      storeLinks: StoreLinks.buzzfeed,
    }
  },
];
