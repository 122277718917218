import variables from './headlinelookbook.module.scss';

function hexToRGBA(hex, alpha = 1) {
  const red = parseInt(hex.slice(1, 3), 16);
  const green = parseInt(hex.slice(3, 5), 16);
  const blue = parseInt(hex.slice(5, 7), 16);

  return `rgba(${red},${green},${blue},${alpha})`;
}

const createPalette = ({ primaryColor, textColor = '#000', subbuzzHeaderBackgroundColor = primaryColor, subbuzzHeaderOpacity = .3 }) => ({
  [variables.primaryColor]: primaryColor,
  [variables.textColor]: textColor,
  [variables.subbuzzHeaderBackground]: hexToRGBA(subbuzzHeaderBackgroundColor, subbuzzHeaderOpacity),
});

// https://www.figma.com/file/wsJ9nxvu51KPS3eCVPyUUR/LookBook-Sponsored-Post?node-id=1017%3A9942
export const LOOKBOOK_PALETTES = {
  'core_peach': createPalette({ primaryColor: '#FFE3D4', subbuzzHeaderOpacity: 1 }),
  'rose': createPalette({ primaryColor: '#FFC0C5' }),
  'watermelon': createPalette({ primaryColor: '#F4A2A2' }),
  'sand': createPalette({ primaryColor: '#F7C597' }),
  'hunter': createPalette({ primaryColor: '#587E6F', textColor: '#fff' }),
  'mint': createPalette({ primaryColor: '#B4DBC8' }),
  'periwinkle': createPalette({ primaryColor: '#6772E5', textColor: '#fff', subbuzzHeaderBackgroundColor: '#8CA2E9' }),  
  'stone': createPalette({ primaryColor: '#B0C1D1' }),
  'black': createPalette({ primaryColor: '#2B3249', textColor: '#fff' }),
  'orange': createPalette({ primaryColor: '#BB6719' }),
};

export const LOOKBOOK_HEADER_IMAGES = {
  6213166: {
    desktop: [{
      src: 'https://img.buzzfeed.com/store-an-image-prod-us-east-1/2wr8eZ7PB.png',
      alt: 'foo bar',
      caption: 'Image caption here'
    }, {
      src: 'https://img.buzzfeed.com/store-an-image-prod-us-east-1/mrut2VKAq.png',
      alt: 'foo bar',
      caption: 'Image caption here'
    }],
    mobile: [{
      src: 'https://img.buzzfeed.com/store-an-image-prod-us-east-1/2wr8eZ7PB.png',
      alt: 'foo bar',
      caption: 'Image caption here'
    }]
  },
  6318306: {
    desktop: [{
      src: 'https://img.buzzfeed.com/store-an-image-prod-us-east-1/Kkep1TlKv.png',
      alt: ': A woman in a vibrant floral suit and a woman in a mid-length lilac shirt dress',
      caption: 'via Nordstrom',
    }],
    mobile: [{
      src: 'https://img.buzzfeed.com/store-an-image-prod-us-east-1/m4991T6wJ.png',
      alt: ': A woman in a vibrant floral suit and a woman in a mid-length lilac shirt dress',
      caption: 'via Nordstrom',
    }]
  },
  6317663: {
    desktop: [{
      src: 'https://img.buzzfeed.com/store-an-image-prod-us-east-1/ojJJkGRzs.png',
      alt: 'smiling young woman holding gift',
      caption: 'via Nordstrom Rack',
    }],
    mobile: [{
      src: 'https://img.buzzfeed.com/store-an-image-prod-us-east-1/EFWAnH_vV.png',
      alt: 'smiling young woman holding gift',
      caption: 'via Nordstrom Rack',
    }]
  },
  6328731: {
    desktop: [{
      src: 'https://img.buzzfeed.com/store-an-image-prod-us-east-1/_urLEETyh.png',
      alt: 'GUESS Originals - Betty Boop Capsule',
      caption: 'via GUESS Originals',
    }],
    mobile: [{
      src: 'https://img.buzzfeed.com/store-an-image-prod-us-east-1/Vnh84j7By.png',
      alt: 'GUESS Originals - Betty Boop Capsule',
      caption: 'via GUESS Originals',
    }]
  },
  6338670: {
    desktop: [{
      src: 'https://img.buzzfeed.com/store-an-image-prod-us-east-1/YgB-ffkzG.png',
      alt: 'woman wearing a purple suit jacket',
      caption: 'via Express',
    },
    {
      src: 'https://img.buzzfeed.com/store-an-image-prod-us-east-1/QqTTkj6UI.png',
      alt: 'two women wearing grey outfits',
      caption: 'via Express',
    }],
    mobile: [{
      src: 'https://img.buzzfeed.com/store-an-image-prod-us-east-1/YgB-ffkzG.png',
      alt: 'woman wearing a purple suit jacket',
      caption: 'via Express',
    }]
  },
  6343747: {
    desktop: [{
      src: 'https://img.buzzfeed.com/store-an-image-prod-us-east-1/_LYFS3vSe.png',
      alt: 'Inga hosting a potluck party',
      caption: 'Shannon Soule & Morgan Demeter / BuzzFeed',
    },
    {
      src: 'https://img.buzzfeed.com/store-an-image-prod-us-east-1/M3zC6OHNV.png',
      alt: 'Jasmine hosting a cocktail party',
      caption: 'Morgan Demeter / BuzzFeed',
    }],
    mobile: [{
      src: 'https://img.buzzfeed.com/store-an-image-prod-us-east-1/pMMRuj_bo.png',
      alt: 'Inga hosting a potluck party',
      caption: 'Shannon Soule / BuzzFeed',
    }]
  },
  6345368: {
    desktop: [{
      src: 'https://img.buzzfeed.com/store-an-image-prod-us-east-1/DS46CxGjP.png',
      alt: 'Elise sits on couch to wrap presents',
      caption: 'BuzzFeed',
    },
    {
      src: 'https://img.buzzfeed.com/store-an-image-prod-us-east-1/bOUr4sHDm.png',
      alt: 'Elise writes holiday cards',
      caption: 'BuzzFeed',
    }],
    mobile: [{
      src: 'https://img.buzzfeed.com/store-an-image-prod-us-east-1/wmD5lwXnY.png',
      alt: 'Elise sits on couch to wrap presents',
      caption: 'BuzzFeed',
    }]
  },
  6345370: {
    desktop: [{
      src: 'https://img.buzzfeed.com/store-an-image-prod-us-east-1/mVAhnRJRK.png',
      alt: 'Rie puts flannels into boxes lined with tissue paper',
      caption: 'BuzzFeed',
    },
    {
      src: 'https://img.buzzfeed.com/store-an-image-prod-us-east-1/Qsxen0jb1.png',
      alt: 'Rie holds out ribbon above table with wrapping',
      caption: 'BuzzFeed',
    }],
    mobile: [{
      src: 'https://img.buzzfeed.com/store-an-image-prod-us-east-1/AqNNrXv8f.png',
      alt: 'Rie puts flannels into boxes lined with tissue paper',
      caption: 'BuzzFeed',
    }]
  },
  6356153: {
    desktop: [{
      src: 'https://img.buzzfeed.com/store-an-image-prod-us-east-1/BROOyjyP9.png',
      alt: 'Krista wrapping a present',
      caption: 'Shannon Soule / BuzzFeed',
    },
    {
      src: 'https://img.buzzfeed.com/store-an-image-prod-us-east-1/SpwcsDXYJ.png',
      alt: 'Krista opening a present',
      caption: 'Shannon Soule / BuzzFeed',
    }],
    mobile: [{
      src: 'https://img.buzzfeed.com/store-an-image-prod-us-east-1/vPCt23PGQ.png',
      alt: 'Krista wrapping a present',
      caption: 'Shannon Soule / BuzzFeed',
    }]
  },
  6356259: {
    desktop: [{
      src: 'https://img.buzzfeed.com/store-an-image-prod-us-east-1/WALG2BALK.png',
      alt: 'Woman models silver sequin one-shouldered top and skirt',
      caption: 'Express',
    },
    {
      src: 'https://img.buzzfeed.com/store-an-image-prod-us-east-1/6addLd8V9.png',
      alt: 'Woman walks down city crosswalk wearing long black sequin dress and open-toed black heels',
      caption: 'Express',
    }],
    mobile: [{
      src: 'https://img.buzzfeed.com/store-an-image-prod-us-east-1/mKXQxIrY-.png',
      alt: 'Woman models silver sequin one-shouldered top and skirt',
      caption: 'Express',
    }]
  },
  7372811: {
    desktop: [{
      src: 'https://img.buzzfeed.com/thumbnailer-prod-us-east-1/hive/binaries/491935.jpg',
      alt: 'Models lounge in leggings, bras, shorts, and shirts from the Hanes Originals Collection',
      caption: 'Hanes',
    },
    {
      src: 'https://img.buzzfeed.com/thumbnailer-prod-us-east-1/hive/binaries/491939.jpg',
      alt: 'Man wears boxers and tank while woman wears bra and underwear from the Hanes Originals Collection',
      caption: 'Hanes',
    }],
    mobile: [{
      src: 'https://img.buzzfeed.com/thumbnailer-prod-us-east-1/hive/binaries/491937.jpg',
      alt: 'Models lounge in leggings, bras, shorts, and shirts from the Hanes Originals Collection',
      caption: 'Hanes',
    }]
  },
  7538615: {
    desktop: [{
      src: 'https://img.buzzfeed.com/store-an-image-prod-us-east-1/v6ckgPVfn.png',
      alt: 'Two women dressed in fairy halloween costumes taking a selfie outside',
      caption: 'Party City',
    }],
    mobile: [{
      src: 'https://img.buzzfeed.com/store-an-image-prod-us-east-1/v6ckgPVfn.png',
      alt: 'Two women dressed in fairy halloween costumes taking a selfie outside',
      caption: 'Party City',
    }],
  },
  7562607: {
    desktop: [{
      src: 'https://img.buzzfeed.com/store-an-image-prod-us-east-1/DyHTTsHRn.png',
      alt: 'Two dogs lying in front of a christmas tree and fireplace',
      caption: 'Petsmart',
    }],
    mobile: [{
      src: 'https://img.buzzfeed.com/store-an-image-prod-us-east-1/DyHTTsHRn.png',
      alt: 'Two dogs lying in front of a christmas tree and fireplace',
      caption: 'Petsmart',
    }],
  },
  7633098: {
    desktop: [{
      src: 'https://img.buzzfeed.com/store-an-image-prod-us-east-1/dl0YcJ-Rt.png',
      alt: 'Two women standing in a field against a cloud back drop',
      caption: 'via Walmart Canada',
    }],
    mobile: [{
      src: 'https://img.buzzfeed.com/store-an-image-prod-us-east-1/dl0YcJ-Rt.png',
      alt: 'Two women standing in a field against a cloud back drop',
      caption: 'via Walmart Canada',
    }],
  },
  7727906: {
    desktop: [{
      src: 'https://img.buzzfeed.com/store-an-image-prod-us-east-1/8C5A6X2CW.png?output-format=jpg&output-quality=95',
      alt: 'dog in front of a "happy halloween" sign, jack-o-lanterns and decorative bats.',
      caption: 'via Getty Images',
    }],
    mobile: [{
      src: 'https://img.buzzfeed.com/store-an-image-prod-us-east-1/8C5A6X2CW.png?output-format=jpg&output-quality=95',
      alt: 'dog in front of a "happy halloween" sign, jack-o-lanterns and decorative bats.',
      caption: 'via Getty Images',
    }],
  },
  7757785: {
    desktop: [{
      src: 'https://img.buzzfeed.com/store-an-image-prod-us-east-1/eA2VM7NFv.png?output-format=jpg&output-quality=95',
      alt: 'Two kids opening presents from Dick\'s Sporting Good by a Christmas tree',
      caption: 'DICK\'S Sporting Goods',
    }],
    mobile: [{
      src: 'https://img.buzzfeed.com/store-an-image-prod-us-east-1/eA2VM7NFv.png?output-format=jpg&output-quality=95',
      alt: 'Two kids opening presents from Dick\'s Sporting Good by a Christmas tree',
      caption: 'DICK\'S Sporting Goods',
    }],
  },
  7765663: {
    desktop: [{
      src: 'https://img.buzzfeed.com/store-an-image-prod-us-east-1/GVKPPABas.png?output-format=jpg&output-quality=95',
      alt: 'a tin of cookies, a vanilla scented candkle, and two holiday themed mugs',
      caption: 'Walmart Canada',
    }],
    mobile: [{
      src: 'https://img.buzzfeed.com/store-an-image-prod-us-east-1/GVKPPABas.png?output-format=jpg&output-quality=95',
      alt: 'a tin of cookies, a vanilla scented candkle, and two holiday themed mugs',
      caption: 'Walmart Canada',
    }],
  },
  7765768: {
    desktop: [{
      src: 'https://img.buzzfeed.com/store-an-image-prod-us-east-1/tONV_zSyk.png?output-format=jpg&output-quality=95',
      alt: 'table setting with a champagne flute, and pink and green plates that say "noel" with cupcakes on it. table is decorated with a pink and green tree and a disco ball',
      caption: 'Walmart Canada',
    }],
    mobile: [{
      src: 'https://img.buzzfeed.com/store-an-image-prod-us-east-1/tONV_zSyk.png?output-format=jpg&output-quality=95',
      alt: 'table setting with a champagne flute, and pink and green plates that say "noel" with cupcakes on it. table is decorated with a pink and green tree and a disco ball',
      caption: 'Walmart Canada',
    }],
  },
  7829855: {
    desktop: [{
      src: 'https://img.buzzfeed.com/store-an-image-prod-us-east-1/Aay1XOftY.png?output-format=jpg&output-quality=95',
      alt: 'one child and one woman in denim shorts',
      caption: 'Walmart Canada',
    }],
    mobile: [{
      src: 'https://img.buzzfeed.com/store-an-image-prod-us-east-1/Aay1XOftY.png?output-format=jpg&output-quality=95',
      alt: 'one child and one woman in denim shorts',
      caption: 'Walmart Canada',
    }],
  },
  7838751: {
    desktop: [{
      src: 'https://img.buzzfeed.com/store-an-image-prod-us-east-1/h71dtWwyX.png?output-format=jpg&output-quality=95',
      alt: 'family sitting on a patio with patio furniture',
      caption: 'Walmart Canada',
    }],
    mobile: [{
      src: 'https://img.buzzfeed.com/store-an-image-prod-us-east-1/h71dtWwyX.png?output-format=jpg&output-quality=95',
      alt: 'family sitting on a patio with patio furniture',
      caption: 'Walmart Canada',
    }],
  },
};
