import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { isAndroidAgent } from '@buzzfeed/bf-utils/lib/device';
import { withUtmTracking } from '@buzzfeed/site-tracking/shares';
import { ShareIosIcon, ShareAndroidIcon } from '@buzzfeed/react-components';
import { useTranslation } from '../../i18n';
import BuzzContext from '../../contexts/buzz';
import { isNativeShareEligible, triggerNativeShare } from '../../utils/sharing';
import { trackClientContentAction } from '../../hooks/analytics/client-event-tracking';
import styles from './nativeShareButton.module.scss';

function NativeShareButton({
  type = 'page_level',
  variant,
  includeTitle = false,
  includeShortTitle = false,
  includeImage = false,
  subbuzzId = '',
  /**
   * Tracking data specific to the location on the page the button is used
   *    Should always provide the following:
   *     unit_name
   *     unit_type
   *    Optionally can provide (will set to '' if not provided)
   *     subunit_name
   *     subunit_type
   **/
  trackingData = {},
}) {
  const { t } = useTranslation('common');
  const [device, setDevice] = useState(null);
  const buzz = useContext(BuzzContext);

  const baseUrl = `${buzz.canonical_url}${subbuzzId ? '?sub=' + subbuzzId : ''}`;

  const shareUrl = withUtmTracking({
    shareUrl: baseUrl,
    platform: `native${device}`, // results in ?utm_campaign=bfsharenativeios or android
  });

  useEffect(() => {
    if (isNativeShareEligible()) {
      setDevice(isAndroidAgent() ? 'android' : 'ios');
    }
  }, []);

  /**
   * will only know it is a mobile device after page load/client, so hide until then
   */
  if (!variant || (type === 'page_level' && buzz.shouldHideBuzzSharing) || !device) {
    return null;
  }

  const handleShare = async () => {
    const shareValues = {
      // unfurling after sharing doesn't work with stage urls (because of basic auth), so it is
      //  easier for testing to always have prod urls.
      url: shareUrl.replace('https://stage', 'https://www')
    }
    let title = buzz.title;
    if (includeShortTitle) {
      // if it exists a short title will exist within homepage promotion
      const extraFieldsStr = buzz?.socialpromotions?.homepage?.[0]?.extra_fields;
      try {
        const extraFieldsObj = JSON.parse(extraFieldsStr);
        title = extraFieldsObj?.short_headline || title;
      } catch (error) {
        console.error(error);
      }
    }
    if (includeShortTitle || includeTitle) {
      shareValues.text = title
    }
    if (includeImage && buzz.picture) {
      const response = await fetch(`${buzz.picture}?output-quality=auto&output-format=auto`);

      const blob = await response.blob();
      shareValues.files = [
        new File([blob], `buzzfeed-${buzz.title.replace(/\W/g, '-')}.jpg`, {
          type: blob.type,
        }),
      ];
    }
    // compile tracking fields & trigger a CET event
    const trackingFields = {
      action_type: 'share',
      action_value: 'native_share',
      item_type: 'button',
      item_name: 'native_share',
      // then include position specific tracking data
      ...trackingData
    };
    trackClientContentAction(buzz, trackingFields);

    // trigger the share
    triggerNativeShare(shareValues);
  }

  return (
    <button
      className={`${styles.nativeShareButton} ${styles[variant]} ${styles[device]}`}
      onClick={handleShare}
    >
      <div className={styles.icon}>
        {device === 'android' ? <ShareAndroidIcon /> : <ShareIosIcon />}
      </div>
      {variant !== 'icon_only' && t('share')}
    </button>
  );
}

NativeShareButton.propTypes = {
  variant: PropTypes.oneOf(['standard', 'standard_small', 'dark', 'icon_only']).isRequired,
  type: PropTypes.string,
  includeTitle: PropTypes.bool,
  includeShortTitle: PropTypes.bool,
  includeImage: PropTypes.bool,
  subbuzzId: PropTypes.string,
};

export default NativeShareButton;
