import { useContext, useEffect, useRef } from 'react';
import { getElementData } from '@buzzfeed/bf-utils/lib/affiliate-link';
import { isInternalUrl } from '@buzzfeed/bf-utils/lib/internal-url';
import { isNews } from '../../utils/isNews';
import { cleanLink } from './utils';
import {
  trackClientExternalLink,
  trackClientInternalLink,
} from './client-event-tracking';
import DestinationContext from '../../contexts/destination';

export function useLinkTracking(buzz, index) {
  const ref = useRef(null);
  const { destination, base_url } = useContext(DestinationContext);
  const baseUrl = new URL(base_url).hostname;

  useEffect(() => {
    const element = ref.current;

    if (!element) {
      return;
    }
    const isNewsRelatedLinks = Boolean(
      isNews(buzz) && element.querySelector('div.subbuzz-bfp--related_links')
    );

    element.querySelectorAll('a[href]').forEach(link => {
      link.addEventListener('click', () => {
        const data = getElementData(link);
        const anchor = element.querySelector('div.subbuzz-anchor');
        const subbuzzId = anchor && anchor.id ? anchor.id : null;
        // Identify if link originates under the subbuzz shared modal
        const isSubbuzzSharedLink = buzz?.metadata?.subbuzzId && (link.closest(`#subbuzz-shared-modal`) !== null);
        let webContentData = {
          position_in_unit: Number(index),
          unit_type: 'buzz_body',
          unit_name: buzz.id,
          item_type: 'subbuzz',
          item_name: subbuzzId,
          subunit_name: '',
          subunit_type: '',
          link_id: data['link-id'],
        };
        if (isNewsRelatedLinks) {
          webContentData.item_type = 'card';
          webContentData.subunit_name = 'more_on_this';
          webContentData.subunit_type = 'package';
        } else if (isSubbuzzSharedLink) {
          /* This subunitName variable depends on the type of subbuzz/buzz. Initial use case is shopping
          * subbuzzes (text, image, photoset, and product) - which should use `affiliate_link_modal`.
          * If other subbuzzes becomes supported should update how subunitName is defined. */
          const subunitName = 'affiliate_link_modal';
          webContentData.position_in_unit = null;
          webContentData.subunit_name = subunitName;
          webContentData.subunit_type = 'component';
        };
        if (isInternalUrl(data.href, baseUrl)) {
          trackClientInternalLink(buzz, {
            ...webContentData,
            target_content_type: 'url',
            target_content_id: data.href,
          });
        } else {
          trackClientExternalLink(buzz, {
            ...webContentData,
            target_content_url: data.href,
          });
        }
      });
    });
  }, [buzz, destination, index]);

  return ref;
}
