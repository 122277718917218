import React, {
  useEffect,
  useRef
} from 'react';
import Link from 'next/link';
import styles from './studios-promo-unit.module.scss';
import promoUnitImage from '../../public/static/images/f-marry-kill.png';
import {
  attachClientImpressionHandler,
  attachClientExternalLinkHandler
} from '../../hooks/analytics/client-event-tracking';
import { useAmazonSubtagModifier } from '../../hooks/useAmazonSubtagModifier';

const URL = 'https://tv.apple.com/us/movie/f-marry-kill/umc.cmc.2l224j2927seo3rujqf8h245d';

const StudiosPromoUnit = ({ buzz }) => {
  const studiosPromoTitle = useRef(null);
  const studiosPromoLink = useRef(null);
  const studiosPromoImageLink = useRef(null);
  const position = buzz && buzz.sub_buzzes ? buzz.sub_buzzes.length : 0;
  const clickRef = useAmazonSubtagModifier({ buzz });

  useEffect(() => {
    let detachExternalLinkTracking;
    let detachExternalImageLinkTracking;

    const commonTrackingData = {
      item_name: 'primevideo',
      item_type: 'text',
      position_in_unit: position,
      unit_type: 'buzz_body',
      unit_name: buzz.id,
      subunit_type: 'component',
      subunit_name: 'studios_promo',
      target_content_type: 'url',
      target_content_id: URL,
    };

    if (studiosPromoTitle && studiosPromoTitle.current) {
      attachClientImpressionHandler(studiosPromoTitle.current, buzz, {
        ...commonTrackingData,
        position_in_subunit: 0,
      });
    }

    if (studiosPromoImageLink && studiosPromoImageLink.current) {
      attachClientImpressionHandler(studiosPromoImageLink.current, buzz, {
        ...commonTrackingData,
        item_type: 'image',
        position_in_subunit: 1,
      });
    }

    if (studiosPromoLink && studiosPromoLink.current) {
      detachExternalLinkTracking = attachClientExternalLinkHandler(
        studiosPromoLink.current,
        buzz,
        {
          ...commonTrackingData,
          position_in_subunit: 0,
          target_content_url: URL
        }
      );
    }

    if (studiosPromoImageLink && studiosPromoImageLink.current) {
      detachExternalImageLinkTracking = attachClientExternalLinkHandler(
        studiosPromoImageLink.current,
        buzz,
        {
          ...commonTrackingData,
          item_type: 'image',
          position_in_subunit: 1,
          target_content_url: URL
        }
      );
    }

    return () => {
      if (typeof detachExternalLinkTracking === 'function') {
        detachExternalLinkTracking();
      }
      if (typeof detachExternalImageLinkTracking === 'function') {
        detachExternalImageLinkTracking();
      }
    };
  }, [buzz.id, position, studiosPromoLink, studiosPromoImageLink, studiosPromoTitle]);

  /* eslint-disable react/jsx-no-target-blank */
  return (
    <div className={styles.promoUnit} ref={clickRef}>
      <h2 className={styles.promoUnitTitle} ref={studiosPromoTitle}>
        <i>A little serial dating never killed anyone. 📱🔪 F Marry Kill starring Lucy Hale and Virginia Gardner is now playing in select theaters and on digital. <Link href={URL}><a data-affiliate={true} target="_blank" rel="sponsored" ref={studiosPromoLink}>
          Watch the Trailer Now!</a></Link></i>
      </h2>
      <div className={styles.promoUnitImage}>
        <a data-affiliate={true} target="_blank" rel="sponsored" ref={studiosPromoImageLink} href={URL}>
          <img src={promoUnitImage} alt="F Marry Kill Movie"/>
        </a>
      </div>
    </div>
  )
  /* eslint-enable react/jsx-no-target-blank */
}

export default StudiosPromoUnit;
