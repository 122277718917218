import { CommentsCtaButton, withErrorBoundary } from '@buzzfeed/react-components';
import { withTranslation } from '../../../../i18n';
import React, { useContext } from 'react';
import { captureException } from '@sentry/nextjs';
import baseStyles from '../../headline.module.scss';
import styles from './headlinelookbook.module.scss';
import HeadlineTimestamp from '../HeadlineTimestamp';
import DestinationContext from '../../../../contexts/destination';
import HeadlineByline from '../HeadlineByline';
import { useCommentsCtaButtonTracking } from '../../../../hooks/analytics/useCommentsCtaButtonTracking';
import ActionBar from '../../../ActionBar';
import { useEffect } from 'react';
import { LOOKBOOK_HEADER_IMAGES, LOOKBOOK_PALETTES } from './config';

function useLookbookPalette({ paletteName }) {
  useEffect(() => {
    const paletteStyles = LOOKBOOK_PALETTES[paletteName];
    if (!paletteStyles) {
      return () => {};
    }

    for (const [style, value] of Object.entries(paletteStyles)) {
      document.documentElement.style.setProperty(style, value);
    }

    document.documentElement.style.overflowX = 'hidden';
    return () => document.documentElement.style.overflowX = null;
  }, [paletteName]);
}

function ImagesContainer({ images, className, maxWidth }) {
  if (!images) {
    return null;
  }

  return ( // eslint-disable-line consistent-return
    <div className={`${styles.imagesContainer} ${className}`}>
      {images.map(image => (
        <div className={styles.imageWrapper} key={image.src}>
          <img src={`${image.src}?output-quality=100&output-format=auto&resize=${maxWidth}:*`} alt={image.alt} />
          <p className={styles.imageCaption}>{image.caption}</p>
        </div>
      ))}
    </div>
  );
}

function HeadlineLookbook({ buzz, commentsWrapperId, commentCount, showShareBar, t }) {
  const commentsCtaButtonTrackingFn = useCommentsCtaButtonTracking(buzz);
  const { destination } = useContext(DestinationContext);
  const timestampPublished = Number(buzz.published);
  const timestampUpdated =
    'edit_updated' in buzz ? Number(buzz.edit_updated) : 0;
  useLookbookPalette({ paletteName: buzz.lookbookPalette });
  const numberOfImages = LOOKBOOK_HEADER_IMAGES[buzz.id]?.desktop.length;

  return (
    <div className={styles.container}>
      <div className={`${styles.headline} ${baseStyles.headline}`}>
        <div className={`${styles.timestamp} ${baseStyles.container}`}>
          <span className={`${styles.paidPostBreadcrumb} ${baseStyles.paidPostBreadcrumb}`}>{t('paid_post')}</span>
          <HeadlineTimestamp
            timestampPublishedUnix={timestampPublished}
            timestampUpdatedUnix={timestampUpdated}
            countryCode={buzz.country_code}
            destination={destination}
          />
        </div>
        <div className={baseStyles.container}>
          <h1
            className={`${baseStyles.title} ${styles.title}`}
            dangerouslySetInnerHTML={{ __html: buzz.title }}
          />
        </div>
        <div className={styles.imageFullWidthContainer}>
          <ImagesContainer className={styles.desktop} images={LOOKBOOK_HEADER_IMAGES[buzz.id]?.desktop} maxWidth={numberOfImages === 1 ? 1130 : 551} />
          <ImagesContainer className={styles.mobile} images={LOOKBOOK_HEADER_IMAGES[buzz.id]?.mobile} maxWidth={600} />
          <div className={styles.imageBackgroundContainer} />
        </div>
        <div className={styles.contentWidth}>
          <div className={styles.bylineContainer}>
            <HeadlineByline
              bylines={buzz.bylines}
              buzz={buzz}
            />
            <ActionBar
              buzz={buzz}
              className={styles.actionBar}
              showShareBar={showShareBar}
              destination={destination}
              showCommentButton={false}
            />
          </div>
          <p
            className={`${baseStyles.description} ${styles.description}`}
            dangerouslySetInnerHTML={{ __html: buzz.description }}
          />
          <CommentsCtaButton
            commentCount={commentCount}
            track={commentsCtaButtonTrackingFn}
            locale={buzz.language || 'en'}
            commentsWrapperId={commentsWrapperId}
            destination={destination}
          />
        </div>
      </div>
    </div>
  )
}

export default withErrorBoundary(withTranslation('common')(HeadlineLookbook), {
  onError: captureException,
});
