import React, { useEffect, useRef, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import composeRefs from '@seznam/compose-react-refs';
import { useTranslation } from '../../i18n';
import { useSubbuzzes } from './useSubbuzzes';
import { useAIGCWrapper } from '../../hooks/useAIGCWrapper';
import { useAmazonSubtagModifier } from '../../hooks/useAmazonSubtagModifier';
import { useBullwhip } from '../../hooks/useBullwhip';
import { useButtonPostTap } from '../../hooks/useButtonPostTap';
import { useSkimlinksXcustModifier } from '../../hooks/useSkimlinksXcustModifier';
import { useAffiliateLinkTracking } from '../../hooks/analytics/useAffiliateLinkTracking';
import { useLinkTracking } from '../../hooks/analytics/useLinkClickTracking';
import useNavTracking from '../../hooks/analytics/useNavTracking';
import { useProductClicks } from '../../hooks/useProductClicks';
import { SubbuzzInsert } from '../SubbuzzInsert';
import { TopicPromo } from '../TopicPromos';
import { SubbuzzShare } from '../SubbuzzShare'
import {
  attachClientImpressionHandler,
  attachClientSubbuzzImpressionHandler,
} from '../../hooks/analytics/client-event-tracking';
import withInlineAds from './withInlineAds';
import ABeagleContext from '../../contexts/abeagle';
import DestinationContext from '../../contexts/destination';
import { isNews } from '../../utils/isNews';

export const SubbuzzHTML = ({
  html = '',
  buzz,
  index,
}) => {
  const ref = useAffiliateLinkTracking({ buzz });
  const clickRef = useAmazonSubtagModifier({ buzz });
  const clickAIGCRef = useAIGCWrapper({ buzz, index });
  const clickBButtonPostTapRef = useButtonPostTap({ buzz });
  const clickSkimlinksRef = useSkimlinksXcustModifier({ buzz });
  const clickLinkRef = useLinkTracking(buzz, index);
  const clickProductLinkRef = useProductClicks({ buzz });
  const bullwhipRef = useBullwhip();
  const [currentSubbuzzId, setSubbuzzId] = useState('');
  const { experiments, getExperimentValue } = useContext(ABeagleContext);
  const [productSubbuzzVariant, setProductSubbuzzVariant] = useState('control')

  // get related products experiment variant
  useEffect(() => {
    if (experiments && experiments.loaded) {
      setProductSubbuzzVariant(
        getExperimentValue('XX-1600-shopping-subbuzz-sharing', {
          rejectErrors: false,
        })
      );
    }
  }, [experiments, getExperimentValue]);

  useEffect(() => {
    /*
     * On the client `html` will always be an empty string so
     * this data should be queried using DOM APIs.
     */
    let detachImpressionTracking;

    if (ref && ref.current) {
      // SRA returns markup with an empty div for share bar to be inserted
      // Append the share bar and bind share functionality

      // Add impression tracking to subbuzz unit
      // ID will be present in subbuzz HTML
      const anchor = ref.current.querySelector('div.subbuzz-anchor');
      const isNewsRelatedLinks =
        isNews(buzz) &&
        ref.current.querySelector('.subbuzz-bfp--related_links');
      const subbuzzId = anchor && anchor.id ? anchor.id : null;
      setSubbuzzId(subbuzzId);
      if (!isNewsRelatedLinks) {
        detachImpressionTracking = attachClientSubbuzzImpressionHandler(
          ref.current,
          buzz,
          {
            item_name: subbuzzId,
            item_type: 'subbuzz',
            position_in_unit: Number(index),
            unit_type: 'buzz_body',
            unit_name: buzz.id,
            subunit_name: '',
            subunit_type: '',
          }
        );
      } else {
        const relatedLinks = ref.current.querySelectorAll(
          '.bfp-related-links__link'
        );
        relatedLinks.forEach((link, i) => {
          const isInternalNewsLink = link.href.match(
            /^(http:\/\/|https:\/\/)?(www|stage)?.buzzfeednews\.com/g
          );
          attachClientImpressionHandler(link, buzz, {
            subunit_name: 'more_on_this',
            subunit_type: 'package',
            unit_type: 'buzz_body',
            unit_name: buzz.id,
            item_name: link.href,
            item_type: 'card',
            position_in_unit: Number(index),
            position_in_subunit: Number(i),
            target_content_type: isInternalNewsLink ? 'buzz' : 'url',
            target_content_id: link.href,
          });
        });
      }
    }

    return () => {
      if (typeof detachImpressionTracking === 'function') {
        detachImpressionTracking();
      }
    };
  }, [buzz, index, ref]);

  return (
    <div className="js-subbuzz-wrapper">
      <div
        dangerouslySetInnerHTML={{ __html: html }}
        ref={composeRefs(
          ref,
          clickRef,
          clickAIGCRef,
          clickSkimlinksRef,
          clickLinkRef,
          clickProductLinkRef,
          clickBButtonPostTapRef,
          bullwhipRef,
        )}
      />
      <SubbuzzInsert
        buzz={buzz}
        subbuzzEl={ref}
        index={index}
      />
      {['share_enabled_v1', 'share_enabled_v2'].includes(productSubbuzzVariant) &&
        <SubbuzzShare
          buzz={buzz}
          subbuzzId={currentSubbuzzId}
          subbuzzRef={ref}
          location={productSubbuzzVariant}
        />}
    </div>
  );
};

export const Subbuzzes = ({ buzz, subbuzzData = {} }) => {
  const { destination } = useContext(DestinationContext);
  const subbuzzRef = useRef(null);
  const t3App = useSubbuzzes(subbuzzData.assets);

  useNavTracking();
  const { t } = useTranslation('common');
  const displaySubbuzzes = subbuzzData.subbuzzes
    ? [...subbuzzData.subbuzzes]
    : [];

  const classFormats = ['list', 'long'];
  const formatClass = classFormats.reduce((c, format) => {
    if (buzz.format.page_type.includes(format)) {
      c += `buzz--${format}`;
    }
    return c;
  }, '');

  // append updates title HTML to subbuzz data for breaking posts
  if (buzz.format.page_type === 'breaking' && displaySubbuzzes.length) {
    const firstUpdate = displaySubbuzzes.find((s) =>
      s.includes('buzz__update')
    );
    if (firstUpdate) {
      const firstUpdateIndex = displaySubbuzzes.indexOf(firstUpdate);
      displaySubbuzzes.splice(
        firstUpdateIndex,
        0,
        `<h2 class="buzz__update-title">${t('updates')}</h2>`
      );
    }
  }

  // Initialize T3 app
  useEffect(() => {
    if (t3App && t3App.init) {
      t3App.init(buzz);
    }

    return () => {
      if (t3App && t3App.destroy) {
        t3App.destroy();
      }
    };
  }, [buzz, t3App]);

  /*
    Calculate placement of Public Good action unit to be after last line of article
    and above footer byline, bottom "interested in" unit, and "more on this" subbuzz
  */
  useEffect(() => {
    if (destination !== 'buzzfeed_news' || !subbuzzRef?.current) return;

    const subbuzzWrapperNodes = subbuzzRef.current.querySelectorAll(
      '.js-subbuzz-wrapper'
    );
    let bottomIndex = subbuzzWrapperNodes.length - 1;

    if (
      subbuzzWrapperNodes[bottomIndex - 1]?.querySelector(
        /* This accounts for the edge case of an empty text subbuzz at the end of an article */
        '.subbuzz-bfp--related_links'
      )
    ) {
      bottomIndex -= 1;
    }
  }, [
    destination,
    subbuzzRef,
  ]);

  return (
    <div
      className={`
          ${formatClass}
          buzz--${buzz.category}
          subbuzzes-wrapper
          subbuzzes--${destination}
        `}
      ref={subbuzzRef}
    >
      {displaySubbuzzes.map((sb, i) => {
        return (
          <React.Fragment key={i}>
            {i === 0 && buzz?.sub_buzzes[0]?.format_name !== 'connatix_video' && <TopicPromo buzz={buzz} itemType="text" positionInUnit={i} />}
            {i === 1 && buzz?.sub_buzzes[0]?.format_name === 'connatix_video' && <TopicPromo buzz={buzz} itemType="text" positionInUnit={i} />}
            <SubbuzzHTML
              key={i}
              html={sb}
              buzz={buzz}
              index={i}
            />
            {
              (i === 3 || (i === displaySubbuzzes?.length - 1 && displaySubbuzzes?.length < 4))
              &&
                <TopicPromo
                  buzz={buzz}
                  itemType="card"
                  positionInUnit={i}
                  unitType={displaySubbuzzes?.length >= 4 ? 'buzz_body' : 'buzz_bottom'}
                />}
          </React.Fragment>
        );
      })}
    </div>
  );
};

Subbuzzes.propTypes = {
  subbuzzData: PropTypes.shape({
    assets: PropTypes.object,
    subbuzzes: PropTypes.array,
  }),
};

export default withInlineAds(Subbuzzes);
