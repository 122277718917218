import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  attachClientImpressionHandler,
  trackClientContentAction,
} from '../../hooks/analytics/client-event-tracking';
import { XIcon } from '@buzzfeed/react-components';
import styles from './subbuzzsharedmodal.module.scss';
import { SubbuzzHTML } from '../Subbuzzes';
import { useTranslation } from '../../i18n';


export function SubbuzzSharedModal({
    buzz,
    isOpen,
    onRequestClose,
    subbuzzData = {},
    subbuzzId,
  }) {
  const closeRef = useRef(null);
  const { t } = useTranslation('common');
  const sbIndex = buzz['sub_buzzes'].map(({ id }) => id).indexOf(subbuzzId);
  const displaySubbuzz = (sbIndex !== -1) ? subbuzzData?.subbuzzes?.[sbIndex] : '';

/* This subunitName variable depends on the type of subbuzz/buzz. Initial use case is shopping
* subbuzzes (text, image, photoset, and product) - which should use `affiliate_link_modal`.
* If other subbuzzes becomes supported should update how subunitName is defined. */
const subunitName = 'affiliate_link_modal';

const unitTrackingData = useMemo(
  () => ({
    subunit_type: 'component',
    subunit_name: subunitName,
    unit_type: 'modal',
    unit_name: buzz.id,
  }),
  [buzz.id, sbIndex]
);

const trackCloseClick = useCallback(() => {
  trackClientContentAction(buzz, {
    ...unitTrackingData,
    action_type: 'close',
    action_value: 'subbuzz_modal',
    item_type: 'button',
    item_name: 'close_modal',
  });
}, [unitTrackingData]);

const trackReadArticleClick = useCallback(() => {
  trackClientContentAction(buzz, {
    ...unitTrackingData,
    action_type: 'close',
    action_value: 'subbuzz_modal',
    item_type: 'button',
    item_name: 'read_article',
  });
}, [unitTrackingData]);

const readArticleRef = useRef(null);
useEffect(() => {
  let detachImpressionTracking;
  if (readArticleRef.current) {
    detachImpressionTracking = attachClientImpressionHandler(readArticleRef.current, buzz, {
      ...unitTrackingData,
      item_type: 'button',
      item_name: 'read_article',
      target_content_type: 'buzz',
      target_content_id: buzz.id,
    });
  };
  return () => {
    if (typeof detachImpressionTracking === 'function') {
      detachImpressionTracking();
    }
  };
}, []);

const handleModalClick = (e) => {
  e.stopPropagation();
};

useEffect(() => {
  const overflowClassName = 'overflow-hidden';
  const isOpening = isOpen && (sbIndex !== -1);
  if (isOpening) { closeRef.current.focus(); }
  document.body.classList.toggle(overflowClassName, isOpening);
  return () => {
    document.body.classList.remove(overflowClassName);
  };
}, [isOpen]);

useEffect(() => {
  if (sbIndex == -1) {
    onRequestClose();
  }
}, [sbIndex]);

if (sbIndex == -1) {
  return (<></>);
}

return (
  <div className={`${styles.subbuzzShareModalOverlay} ${isOpen ? styles.open : styles.closed}`}
       onClick={onRequestClose}
       aria-labelledby={buzz.title}
       role="dialog"
       aria-modal="true"
       aria-hidden="false"
       id="subbuzz-shared-modal"
       >
      <div className={styles.subbuzzShareModalModal} onClick={handleModalClick}>
        <div className={`${styles.header}`} >
          <div></div>
          <div className={styles.title}>
            {buzz.title}
          </div>
          <div>
            <button
              ref={closeRef}
              className={styles.closeButton}
              onClick={() => {
                trackCloseClick();
                onRequestClose();
              }}
              data-dismiss="modal"
              aria-label="Close">
              <XIcon />
            </button>
          </div>
        </div>
        <div className={styles.content}>
          <SubbuzzHTML
            key={`subbuzz-share-modal-${subbuzzId}`}
            html={displaySubbuzz}
            buzz={buzz}
            index={sbIndex}
          />
        </div>
        <div className={styles.footer}>
          <button
            ref={readArticleRef}
            aria-label="Read Article"
            className={`${styles.readArticleButton} ${styles.standard}`}
            onClick={() => {
              trackReadArticleClick();
              onRequestClose();
            }}>
            {t('read_article')}
          </button>
        </div>
      </div>
  </div>
);
}

SubbuzzSharedModal.propTypes = {
buzz: PropTypes.object,
isOpen: PropTypes.bool,
onRequestClose: PropTypes.func,
subbuzzData: PropTypes.shape({
  assets: PropTypes.object,
  subbuzzes: PropTypes.array,
}),
};

SubbuzzSharedModal.defaultProps = {
isOpen: false,
onRequestClose: () => {},
};

export default SubbuzzSharedModal;
