export const DesktopTopLeft = ({ className, title }) => (
  <svg
    className={className}
    width="70"
    height="110"
    viewBox="0 0 70 110"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>{title}</title>
    <path
      d="M16.588 26.4197C16.3097 25.5489 16.7033 24.6989 17.4663 24.523L24.0372 23.0084C32.5086 21.0557 43.3848 27.8246 48.2901 38.0947C50.8458 43.4667 51.4041 49.0667 49.8103 53.4629C48.4056 57.3169 45.4852 59.9393 41.5825 60.8389L36.2462 62.0689C35.4833 62.2448 34.6376 61.6804 34.3594 60.8096C34.0811 59.9388 34.4747 59.0887 35.2377 58.9129L40.5739 57.6828C43.6536 56.973 45.9559 54.918 47.0536 51.9055C48.3509 48.3369 47.8549 43.7085 45.7255 39.2355C41.4954 30.3794 32.2241 24.5155 25.0474 26.1697L18.4765 27.6843C17.7136 27.8602 16.8679 27.2958 16.5897 26.425L16.588 26.4197Z"
      fill="#222222"
    />
    <path
      d="M3.41035 11.072L2.70878 17.922L9.82641 23.1112L9.12485 29.9612L16.2626 35.1521L15.5332 41.9784L22.6664 47.1703L21.9447 54.0186L29.0824 59.2095L28.3627 66.0632L35.4985 71.2487L34.2855 76.6377L-24.5796 89.0953L-43.1005 21.7587L3.41035 11.072Z"
      fill="#F9F7F7"
    />
    <path
      d="M-25.9307 89.3664C-25.948 89.317 -25.9634 89.273 -25.9761 89.2227L-44.49 21.9067C-44.7251 21.0541 -44.3749 20.2841 -43.6792 20.1251L2.81619 9.44714C3.30035 9.33877 3.8558 9.54557 4.28248 9.99782C4.70461 10.451 4.93281 11.075 4.87533 11.6357L4.2937 17.32L10.408 21.7818C11.0085 22.2149 11.3606 22.9837 11.289 23.6712L10.7074 29.3555L16.8398 33.8134C17.4358 34.2475 17.7989 35.0199 17.7201 35.7148L17.1133 41.3691L23.2393 45.8225C23.8353 46.2566 24.1984 47.029 24.1222 47.7174L23.527 53.4046L29.6549 57.8635C30.2509 58.2976 30.614 59.07 30.5378 59.7584L29.9426 65.4456L36.0686 69.899C36.7292 70.3784 37.0841 71.2528 36.9228 71.9769L35.7129 77.3578C35.6019 77.8354 35.286 78.1735 34.8499 78.2658L-23.9932 90.7196C-24.3748 90.8004 -24.8009 90.6842 -25.179 90.3986C-25.5164 90.1456 -25.7839 89.7717 -25.9261 89.3654L-25.9307 89.3664ZM-41.2746 23.1192L-23.6706 87.1247L33.001 75.1305L33.7589 71.7619L27.7622 67.3991C27.1662 66.965 26.805 66.1981 26.8793 65.5042L27.4745 59.817L21.3466 55.3581C20.7506 54.924 20.3875 54.1516 20.4637 53.4632L21.059 47.776L14.9329 43.3226C14.337 42.8885 13.9739 42.1161 14.0527 41.4212L14.6575 35.7614L8.53152 31.308C7.93554 30.8739 7.57893 30.1061 7.64864 29.4131L8.23026 23.7288L2.11595 19.267C1.51997 18.8329 1.16335 18.0651 1.23498 17.3776L1.65569 13.2548L-41.2791 23.1201L-41.2746 23.1192Z"
      fill="#222222"
    />
    <path
      d="M37.8053 91.2761L33.193 86.3418L37.0017 83.0926L32.5986 79.1681L36.5063 75.2663L32.1717 71.3264L-31.5272 85.6963L-18.9132 104.071L37.8053 91.2761Z"
      fill="#FF9C9F"
    />
    <path
      d="M-32.834 86.0523C-32.9551 85.6902 -32.9865 85.3145 -32.9168 84.9745C-32.8072 84.4246 -32.4576 84.0284 -31.9797 83.9215L31.4682 69.7229C31.9217 69.6214 32.4396 69.791 32.86 70.1704L37.1775 74.0694C37.591 74.444 37.8742 74.9774 37.9466 75.5385C38.017 76.0936 37.8718 76.5997 37.5487 76.9185L35.0018 79.441L37.652 81.7865C38.089 82.1688 38.3844 82.7384 38.4413 83.316C38.5031 83.8924 38.3307 84.4111 37.9674 84.7194L35.5395 86.7677L38.5941 90.0087C39.0606 90.4946 39.3114 91.1975 39.2448 91.8287C39.1781 92.4598 38.8097 92.925 38.2831 93.0429L-18.2121 105.686C-18.8167 105.821 -19.5133 105.477 -19.96 104.831L-32.5176 86.6691C-32.6517 86.4721 -32.7585 86.2624 -32.8312 86.0452L-32.834 86.0523ZM31.9538 73.4934L-28.6565 87.057L-18.4197 101.853L34.4528 90.0208L32.1613 87.5885C31.7759 87.1883 31.5463 86.6429 31.5064 86.1004C31.4733 85.5629 31.6481 85.0827 31.9882 84.799L34.2513 82.8849L31.7195 80.6426C31.3011 80.2692 31.002 79.7198 30.9337 79.1708C30.8564 78.6107 31.0044 78.0976 31.3275 77.7787L33.89 75.2398L31.9518 73.4874L31.9538 73.4934Z"
      fill="#222222"
    />
  </svg>
);

export const DesktopTopRight = ({ className, title }) => (
  <svg
    className={className}
    width="86"
    height="123"
    viewBox="0 0 86 123"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>{title}</title>
    <path
      d="M82.2148 53.3186C79.1806 51.4317 75.9115 49.9746 72.5452 48.9813C78.6368 46.6095 80.9036 40.9363 78.465 33.6684C77.1565 29.7685 73.9359 24.3128 65.94 20.6325C62.592 19.1288 58.7545 18.4257 54.3291 18.6072L53.4567 16.0073C52.9606 14.3403 52.0124 12.8327 50.7109 11.5902C48.6798 9.67984 45.9435 8.86909 43.4074 9.40848C39.8098 10.1737 37.0651 13.6695 38.8098 18.8693L39.8606 22.001C35.2241 24.1899 31.5154 27.6376 29.0092 32.0323C25.7397 37.9187 25.384 44.7681 27.9217 52.3315C30.0233 58.5949 33.7544 63.6888 38.9377 67.4609C41.736 69.398 44.7102 70.9178 47.8213 72.092L47.7623 72.1045C43.8499 72.8734 40.6894 75.3181 39.0856 78.8245C37.58 82.2467 37.4725 86.2578 38.8202 90.0861C40.2477 94.3405 43.8039 100.231 52.626 103.926C57.1356 105.879 62.3699 106.601 68.1126 106.203L69.5797 110.575L69.7378 110.858C71.4399 114.801 75.9699 117.003 79.9204 115.783C83.4581 114.651 85.2978 111.094 84.1667 107.346L82.422 102.147C87.8435 99.2842 91.7088 95.5501 93.8998 90.9692C96.756 84.9808 96.8747 77.802 94.0793 69.4704C91.6015 62.2741 87.6335 56.8508 82.2148 53.3186Z"
      fill="#222222"
      stroke="#222222"
      strokeWidth="0.54"
      strokeMiterlimit="10"
    />
    <path
      d="M80.3618 50.6047C77.3274 48.7178 74.0581 47.2607 70.7506 46.2548C76.8426 43.8829 79.1096 38.2096 76.6708 30.9414C75.3622 27.0414 72.1413 21.5856 64.1449 17.9052C60.7968 16.4015 56.9589 15.6983 52.5332 15.8799L51.6608 13.2799C51.1647 11.6128 50.2164 10.1052 48.9148 8.86261C46.9034 7.01132 44.0881 6.154 41.6307 6.73995C38.0328 7.50515 35.2879 11.0011 37.0327 16.201L38.0836 19.3328C33.4468 21.5218 29.7379 24.9695 27.2905 29.3518C23.9816 35.31 23.6259 42.1596 26.144 49.6641C28.2457 55.9277 31.977 61.0217 37.1606 64.794C39.9591 66.7311 42.9336 68.2509 46.0448 69.4252L45.9858 69.4377C42.0732 70.2065 38.9124 72.6514 37.3086 76.1578C35.8029 79.5801 35.6954 83.5914 37.0823 87.3481C38.5297 91.6617 42.0861 97.5526 50.889 101.188C55.399 103.141 60.5746 103.876 66.3767 103.465L67.8439 107.838L68.002 108.121C69.7041 112.064 74.2344 114.266 78.1853 113.046C81.7232 111.913 83.563 108.357 82.4318 104.609L80.687 99.4089C86.1089 96.5465 89.9744 92.8123 92.1656 88.2313C95.0809 82.2301 95.1209 75.0046 92.3451 66.7319C89.7492 59.5604 85.7809 54.137 80.3618 50.6047Z"
      fill="#F0CBA3"
      stroke="#222222"
      strokeWidth="0.93"
      strokeMiterlimit="10"
    />
  </svg>
);

export const DesktopBottomRight = ({ className, title }) => (
  <svg
    width={91}
    height={105}
    viewBox="0 0 91 105"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <title>{title}</title>
    <g clipPath="url(#clip0_5173_27913)">
      <path
        d="M78.022 72.07l2.85 5.23-5.833 3.178-2.767-5.078c-5.027 2.15-9.473 2.41-13.628 1.235l.49-9.31a14.3 14.3 0 0011.2-.944c2.672-1.456 3.526-3.74 2.514-5.596-1.218-2.236-3.98-2.108-7.743-1.58-5.708.9-13.241 2.302-17.558-5.618-2.85-5.229-1.952-11.91 3.847-16.496l-2.891-5.305 5.832-3.179 2.85 5.23c3.614-1.43 7.598-1.733 11.413-.913l-.447 8.943a13.28 13.28 0 00-9.538 1.217c-2.559 1.394-3.098 3.36-2.19 5.027 1.095 2.008 3.76 1.883 7.636 1.294 5.73-.862 13.332-1.958 17.462 5.62 3.305 6.064 2.183 12.425-3.499 17.045z"
        fill="#222"
      />
      <path
        d="M74.874 71.132l2.85 5.23-5.833 3.178-2.767-5.077c-5.027 2.15-9.473 2.41-13.628 1.235l.51-9.273a14.3 14.3 0 0011.2-.943c2.672-1.456 3.526-3.74 2.514-5.597-1.218-2.236-3.979-2.107-7.742-1.58-5.73.862-13.28 2.323-17.596-5.597-2.85-5.23-1.952-11.91 3.884-16.517l-2.912-5.343 5.833-3.178 2.85 5.229c3.614-1.43 7.597-1.733 11.412-.912l-.446 8.942a13.28 13.28 0 00-9.538 1.217c-2.559 1.395-3.099 3.36-2.19 5.027 1.095 2.009 3.76 1.883 7.636 1.294 5.692-.84 13.332-1.957 17.462 5.621 3.342 6.042 2.183 12.424-3.499 17.044z"
        fill="#6EA28F"
        stroke="#222"
        strokeWidth={0.93}
        strokeMiterlimit={10}
      />
    </g>
    <g clipPath="url(#clip1_5173_27913)">
      <path
        d="M108.022 51.07l2.85 5.23-5.833 3.178-2.767-5.078c-5.027 2.15-9.473 2.41-13.628 1.235l.49-9.31a14.3 14.3 0 0011.2-.944c2.672-1.456 3.526-3.74 2.514-5.596-1.218-2.236-3.98-2.108-7.743-1.58-5.708.9-13.241 2.302-17.558-5.618-2.85-5.229-1.952-11.91 3.847-16.496l-2.891-5.305 5.832-3.179 2.85 5.23c3.614-1.43 7.598-1.733 11.413-.913l-.447 8.943a13.28 13.28 0 00-9.538 1.217c-2.558 1.395-3.098 3.36-2.19 5.027 1.095 2.008 3.76 1.883 7.636 1.294 5.73-.862 13.332-1.958 17.462 5.62 3.304 6.064 2.183 12.425-3.499 17.045z"
        fill="#222"
      />
      <path
        d="M104.874 50.132l2.85 5.23-5.833 3.178-2.767-5.077c-5.027 2.15-9.473 2.41-13.628 1.234l.51-9.272a14.3 14.3 0 0011.2-.943c2.672-1.456 3.526-3.74 2.514-5.597-1.218-2.236-3.979-2.107-7.742-1.58-5.73.862-13.28 2.323-17.596-5.597-2.85-5.23-1.952-11.91 3.884-16.517l-2.912-5.343 5.833-3.179 2.85 5.23c3.614-1.43 7.597-1.733 11.412-.912l-.446 8.942a13.28 13.28 0 00-9.538 1.217c-2.559 1.395-3.099 3.36-2.19 5.027 1.094 2.009 3.76 1.883 7.636 1.294 5.692-.84 13.332-1.957 17.462 5.621 3.342 6.042 2.183 12.424-3.499 17.044z"
        fill="#6EA28F"
        stroke="#222"
        strokeWidth={0.93}
        strokeMiterlimit={10}
      />
    </g>
    <g clipPath="url(#clip2_5173_27913)">
      <path
        d="M44.022 89.07l2.85 5.23-5.833 3.178-2.767-5.078c-5.027 2.15-9.473 2.41-13.628 1.235l.49-9.31a14.3 14.3 0 0011.2-.944c2.672-1.456 3.526-3.74 2.514-5.597-1.218-2.235-3.98-2.107-7.743-1.58-5.708.9-13.241 2.303-17.558-5.617-2.85-5.229-1.952-11.91 3.847-16.496l-2.891-5.305 5.832-3.179 2.85 5.23c3.614-1.43 7.598-1.733 11.413-.913l-.447 8.943a13.28 13.28 0 00-9.538 1.217c-2.558 1.394-3.098 3.36-2.19 5.027 1.095 2.008 3.76 1.883 7.636 1.294 5.73-.862 13.332-1.958 17.462 5.62 3.304 6.063 2.183 12.425-3.499 17.045z"
        fill="#222"
      />
      <path
        d="M40.874 88.132l2.85 5.23-5.833 3.178-2.767-5.077c-5.027 2.15-9.473 2.41-13.628 1.235l.51-9.273a14.3 14.3 0 0011.2-.943c2.672-1.456 3.526-3.74 2.514-5.597-1.218-2.236-3.979-2.107-7.742-1.58-5.73.862-13.28 2.323-17.596-5.597-2.85-5.23-1.952-11.91 3.884-16.517l-2.912-5.343 5.833-3.178 2.85 5.229c3.614-1.43 7.597-1.733 11.412-.912l-.446 8.942a13.28 13.28 0 00-9.538 1.217c-2.559 1.395-3.099 3.36-2.19 5.028 1.095 2.008 3.76 1.882 7.636 1.293 5.691-.84 13.332-1.957 17.462 5.621 3.342 6.043 2.183 12.424-3.499 17.044z"
        fill="#6EA28F"
        stroke="#222"
        strokeWidth={0.93}
        strokeMiterlimit={10}
      />
    </g>
    <defs>
      <clipPath id="clip0_5173_27913">
        <path
          fill="#fff"
          transform="rotate(-28.59 89.195 -48.322)"
          d="M0 0H33V58H0z"
        />
      </clipPath>
      <clipPath id="clip1_5173_27913">
        <path
          fill="#fff"
          transform="rotate(-28.59 62.987 -117.691)"
          d="M0 0H33V58H0z"
        />
      </clipPath>
      <clipPath id="clip2_5173_27913">
        <path
          fill="#fff"
          transform="rotate(-28.59 105.554 26.895)"
          d="M0 0H33V58H0z"
        />
      </clipPath>
    </defs>
  </svg>
);

export const DesktopBottomLeft = ({ className, title }) => (
  <svg
    width={49}
    height={231}
    viewBox="0 0 49 231"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <title>{title}</title>
    <path
      d="M-22.09 118.223l-3.12-16.053c-1.258-6.615 2.795-12.9 9.13-13.957C-9.747 87.156-3.534 91.6-2.276 98.215L.75 113.866"
      stroke="#222"
      strokeWidth={1.45}
      strokeMiterlimit={10}
    />
    <path
      d="M27.545 112.716l-78.013 13.407 19.994 101.122 78.012-13.407-19.993-101.122zM18.113 24.282l-79.77 5.646 5.75 55.955 79.77-5.646-5.75-55.955z"
      fill="#000"
      stroke="#222"
      strokeWidth={1.45}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M22.314 108.376l-78.012 13.407 19.993 101.122 78.013-13.406-19.994-101.123z"
      fill="#F4A2A2"
      stroke="#222"
      strokeWidth={1.45}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.89 20.263l-79.77 5.645 5.751 55.955 79.77-5.645-5.75-55.955z"
      fill="#79A191"
      stroke="#222"
      strokeWidth={1.45}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.403 109.199L34.41 209.794M3.509 111.461l18.006 100.595M-9.056 114.109L8.948 214.625"
      stroke="#fff"
      strokeWidth={4.31}
      strokeMiterlimit={10}
    />
    <path
      d="M22.314 108.376l-78.012 13.407 19.993 101.122 78.013-13.406-19.994-101.123z"
      stroke="#222"
      strokeWidth={1.45}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M-18.765 34.946c-3.344 2.708-4.421 7.563-2.596 11.732 2.052 4.085 6.412 6.364 10.618 5.5-.984 5.175-3.304 9.886-6.829 13.645 6.62-.495 12.367-4.607 14.995-10.935C-.37 49.453-.766 41.312-4.71 36.446c-3.2-3.827-10.483-4.291-14.055-1.5z"
      fill="#B4DBC8"
    />
  </svg>
);

export const MobileBottomLeft = ({ className, title }) => (
  <svg
    className={className}
    width="71"
    height="80"
    viewBox="0 0 71 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>{title}</title>
    <path
      d="M57.5738 34.8247L-1.13379 19.4531L-15.9616 63.7258L42.746 79.0974L57.5738 34.8247Z"
      fill="black"
      stroke="#222222"
      strokeWidth="1.45"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.2451 26.601L14.5039 14.0005C16.2926 8.80714 21.5116 5.57132 26.2181 6.83241C30.9246 8.0935 33.3414 13.2701 31.6082 18.4817L27.4706 30.7737"
      stroke="#222222"
      strokeWidth="1.45"
      strokeMiterlimit="10"
    />
    <path
      d="M16.9894 28.9971L21.2476 16.3979C23.0361 11.2051 28.2301 8.03131 32.9046 9.21242C37.6105 10.4734 40.027 15.6495 38.294 20.8605L34.1569 33.1512"
      stroke="#222222"
      strokeWidth="1.45"
      strokeMiterlimit="10"
    />
    <path
      d="M54.8833 30.3951L-3.82422 15.0234L-18.652 59.2961L40.0556 74.6677L54.8833 30.3951Z"
      fill="#79A191"
      stroke="#222222"
      strokeWidth="1.45"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.44595 29.3386C6.1632 30.6084 3.79429 33.9741 3.70173 37.5352C3.7977 41.0891 6.145 43.7941 9.40191 44.1734C6.99212 47.8019 3.79326 50.7569 0.0586811 52.707C4.87276 54.0123 10.3181 52.2788 14.27 48.19C17.6294 44.6662 20.0471 38.4848 18.8737 33.8863C17.9378 30.2636 12.8933 28.1229 9.44595 29.3386Z"
      fill="#B4DBC8"
    />
    <path
      d="M27.4967 34.6277C24.2379 35.8363 21.869 39.202 21.7764 42.763C21.8724 46.3169 24.1958 49.0831 27.4527 49.4624C25.0429 53.091 21.8441 56.0459 18.1095 57.9961C22.9475 59.2401 28.3689 57.5679 32.3208 53.479C35.6802 49.9553 38.0978 43.7738 36.9245 39.1753C35.9338 35.5347 30.9441 33.412 27.4967 34.6277Z"
      fill="#B4DBC8"
    />
  </svg>
);

export const MobileBottomRight = ({ className, title }) => (
  <svg
    width={96}
    height={107}
    viewBox="0 0 96 107"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <title>{title}</title>
    <g clipPath="url(#clip0_5082_28265)">
      <path
        d="M68.43 72.602l1.239 4.716-5.26 1.383-1.204-4.58c-4.418.723-8.003.057-11.076-1.693l2.217-7.305a11.71 11.71 0 009.089 1.448c2.41-.633 3.536-2.28 3.096-3.955-.53-2.017-2.75-2.456-5.845-2.776-4.714-.405-10.977-.769-12.854-7.911-1.24-4.717.786-9.852 6.295-12.359l-1.257-4.784 5.26-1.383 1.24 4.717c3.153-.427 6.38.114 9.25 1.515l-2.11 7.02a10.873 10.873 0 00-7.82-.904c-2.308.607-3.123 2.063-2.727 3.567.475 1.81 2.618 2.234 5.816 2.527 4.723.44 10.981 1.06 12.777 7.895 1.437 5.468-.703 10.305-6.126 12.862z"
        fill="#222"
      />
      <path
        d="M66.111 71.239l1.24 4.716-5.261 1.382-1.203-4.58c-4.418.723-8.004.058-11.076-1.693l2.226-7.27a11.71 11.71 0 009.088 1.448c2.41-.633 3.537-2.28 3.097-3.955-.53-2.017-2.75-2.456-5.845-2.776-4.723-.44-11.012-.76-12.888-7.903-1.24-4.716.786-9.851 6.329-12.367l-1.266-4.819 5.26-1.382 1.24 4.716c3.153-.426 6.379.114 9.25 1.516l-2.11 7.02a10.873 10.873 0 00-7.82-.904c-2.308.606-3.123 2.063-2.728 3.566.476 1.812 2.619 2.235 5.816 2.527 4.69.449 10.982 1.06 12.778 7.896 1.47 5.459-.703 10.304-6.127 12.862z"
        fill="#6EA28F"
        stroke="#222"
        strokeWidth={0.93}
        strokeMiterlimit={10}
      />
    </g>
    <g clipPath="url(#clip1_5082_28265)">
      <path
        d="M96.398 61.798l1.24 4.716-5.261 1.382-1.203-4.58c-4.418.723-8.004.057-11.076-1.693l2.217-7.305a11.71 11.71 0 009.088 1.449c2.41-.634 3.537-2.281 3.097-3.956-.53-2.016-2.75-2.456-5.845-2.775-4.714-.406-10.978-.77-12.854-7.912-1.24-4.716.785-9.852 6.295-12.359l-1.257-4.784 5.26-1.382 1.24 4.716c3.153-.427 6.379.114 9.25 1.515l-2.11 7.021a10.873 10.873 0 00-7.82-.904c-2.308.606-3.123 2.062-2.728 3.566.476 1.811 2.62 2.235 5.816 2.527 4.724.44 10.982 1.06 12.778 7.895 1.437 5.468-.703 10.305-6.127 12.863z"
        fill="#222"
      />
      <path
        d="M94.08 60.434l1.24 4.716-5.261 1.383-1.204-4.58c-4.418.722-8.003.057-11.075-1.693l2.226-7.27a11.709 11.709 0 009.088 1.447c2.41-.633 3.537-2.28 3.097-3.955-.53-2.016-2.75-2.456-5.845-2.775-4.724-.44-11.012-.76-12.889-7.903-1.239-4.716.786-9.852 6.33-12.368l-1.267-4.818 5.261-1.382 1.24 4.716c3.152-.427 6.379.114 9.25 1.515l-2.11 7.02a10.873 10.873 0 00-7.82-.904c-2.308.607-3.123 2.063-2.728 3.567.476 1.81 2.619 2.234 5.816 2.527 4.69.448 10.982 1.06 12.778 7.895 1.47 5.46-.703 10.305-6.127 12.862z"
        fill="#6EA28F"
        stroke="#222"
        strokeWidth={0.93}
        strokeMiterlimit={10}
      />
    </g>
    <g clipPath="url(#clip2_5082_28265)">
      <path
        d="M38.065 79.442l1.24 4.716-5.261 1.382-1.203-4.58c-4.418.723-8.004.058-11.076-1.692l2.217-7.305a11.709 11.709 0 009.088 1.448c2.41-.633 3.537-2.28 3.097-3.956-.53-2.016-2.75-2.456-5.845-2.775-4.714-.405-10.978-.769-12.854-7.912-1.24-4.716.785-9.851 6.295-12.358l-1.257-4.784 5.26-1.383 1.24 4.716c3.153-.426 6.379.115 9.25 1.516l-2.11 7.02a10.873 10.873 0 00-7.82-.904c-2.308.606-3.123 2.063-2.728 3.566.476 1.812 2.62 2.235 5.816 2.528 4.724.44 10.982 1.06 12.778 7.895 1.437 5.468-.703 10.305-6.127 12.862z"
        fill="#222"
      />
      <path
        d="M35.747 78.079l1.24 4.716-5.261 1.382-1.204-4.58c-4.418.723-8.003.058-11.075-1.693l2.226-7.27a11.709 11.709 0 009.088 1.448c2.41-.633 3.537-2.281 3.097-3.956-.53-2.016-2.75-2.456-5.845-2.775-4.724-.44-11.012-.76-12.889-7.903-1.239-4.716.786-9.851 6.33-12.367l-1.267-4.819 5.261-1.382 1.24 4.716c3.152-.426 6.378.114 9.25 1.515l-2.11 7.021a10.873 10.873 0 00-7.82-.904c-2.308.606-3.123 2.062-2.728 3.566.476 1.811 2.619 2.235 5.816 2.527 4.69.449 10.982 1.06 12.778 7.896 1.47 5.459-.704 10.304-6.127 12.862z"
        fill="#6EA28F"
        stroke="#222"
        strokeWidth={0.93}
        strokeMiterlimit={10}
      />
    </g>
    <defs>
      <clipPath id="clip0_5082_28265">
        <path
          fill="#fff"
          transform="rotate(-14.721 159.205 -138.257)"
          d="M0 0H27.0202V47.4901H0z"
        />
      </clipPath>
      <clipPath id="clip1_5082_28265">
        <path
          fill="#fff"
          transform="rotate(-14.721 131.368 -251.916)"
          d="M0 0H27.0202V47.4901H0z"
        />
      </clipPath>
      <clipPath id="clip2_5082_28265">
        <path
          fill="#fff"
          transform="rotate(-14.721 170.497 -17.308)"
          d="M0 0H27.0202V47.4901H0z"
        />
      </clipPath>
    </defs>
  </svg>
);



export const MobileTopRight = ({ className, title }) => (
  <svg
    width={70}
    height={74}
    viewBox="0 0 70 74"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <title>{title}</title>
    <path
      d="M18.865 23.168c-.616-.043-1.061-.599-.993-1.24l.588-5.526c.758-7.125 7.6-13.524 15.25-14.272 4-.383 7.675.844 10.08 3.378 2.108 2.227 3.08 5.26 2.731 8.543l-.477 4.488c-.069.641-.625 1.128-1.241 1.085-.616-.043-1.061-.6-.993-1.24l.477-4.489c.276-2.59-.483-4.978-2.13-6.718-1.952-2.06-4.997-3.048-8.328-2.727-6.598.645-12.49 6.072-13.131 12.107l-.588 5.527c-.069.641-.625 1.128-1.241 1.085h-.004z"
      fill="#222"
    />
    <path
      d="M7.942 23.952l3.8 2.72 4.643-4.003 3.8 2.72 4.648-4.017 3.78 2.735 4.648-4.015 3.795 2.736 4.648-4.019 3.799 2.736 4.644-4.018 2.837 2.664-6.356 50.603L3.408 64.2l4.534-40.249z"
      fill="#F9F7F7"
    />
    <path
      d="M46.481 71.965a.753.753 0 01-.093-.01l-43.23-6.593c-.547-.083-.912-.604-.844-1.206l4.54-40.254a1.3 1.3 0 01.656-.989c.36-.191.773-.175 1.085.048l3.155 2.258 3.992-3.44c.39-.339.916-.375 1.297-.101l3.156 2.258 3.994-3.455c.389-.335.92-.38 1.303-.097l3.133 2.268 3.99-3.45c.389-.336.92-.38 1.3-.102l3.155 2.27 3.993-3.451c.389-.336.92-.38 1.3-.102l3.155 2.27 3.99-3.45c.43-.373 1.017-.378 1.4-.022l2.835 2.66c.25.24.373.597.326.972l-6.358 50.609c-.04.328-.206.63-.459.839a1.11 1.11 0 01-.77.266v.004zm-41.88-8.77l41.104 6.269 6.123-48.741-1.775-1.666-3.907 3.377c-.389.336-.916.38-1.3.101l-3.155-2.27-3.993 3.452c-.39.335-.92.379-1.3.101l-3.155-2.27L29.253 25c-.389.336-.92.38-1.303.098l-3.137-2.27-3.99 3.452c-.388.335-.915.376-1.3.101l-3.156-2.258-3.992 3.44c-.388.335-.915.375-1.296.101l-2.29-1.637L4.601 63.2v-.004z"
      fill="#222"
    />
    <path
      d="M65.248 22.745l-3.276 1.968-.803-3.636-2.75 2.095-1.096-3.886-2.745 2.04-5.722 49.832 11.297-4.043 5.095-44.37z"
      fill="#FF9C9F"
    />
    <path
      d="M48.636 72.327a.868.868 0 01-.545-.24c-.248-.232-.372-.595-.328-.97l5.776-49.88a1.29 1.29 0 01.499-.882l2.78-2.043c.266-.195.585-.252.874-.148a.978.978 0 01.597.671l.726 2.545 1.683-1.267c.275-.21.614-.266.908-.147.296.116.515.385.594.733l.517 2.327 2.203-1.31a.898.898 0 011.023.034c.296.224.45.62.403 1.034l-5.144 44.412c-.055.475-.363.884-.77 1.028l-11.43 4.045a.95.95 0 01-.369.054l.003.004zm6.85-50.393l-5.518 47.648 9.313-3.3 4.813-41.565-1.653.983a.915.915 0 01-.861.06 1.003 1.003 0 01-.542-.71l-.484-2.17-1.609 1.21c-.267.199-.596.263-.88.16-.29-.1-.511-.35-.603-.672l-.73-2.56-1.25.916h.004z"
      fill="#222"
    />
  </svg>
);
