import React, { useState, useEffect, useContext, useRef } from 'react';
import PropTypes from 'prop-types';
import styles from './floating-share-button.module.scss';
import debounce from 'just-debounce-it';
import ABeagleContext from '../../../contexts/abeagle';
import BuzzContext from '../../../contexts/buzz';
import { isNativeShareEligible } from '../../../utils/sharing';
import NativeShareButton from '../../NativeShareButton';


export const FloatingShareButton = ({ className = '', isFloatingShareButtonEnd }) => {
  const [isMobileShareEnabled, setIsMobileShareEnabled] = useState(false); // Is able to native share on mobile device
  const [isVisible, setIsVisible] = useState(false); // Is share being displayed
  const [isScrolling, setIsScrolling] = useState(false); // Is user actively scrolling
  const [isStartTriggered, setIsStartTriggered] = useState(false); // Is within active content area
  const [isEndTriggered, setIsEndTriggered] = useState(false); // Is bellow active content area
  const [endPosition, setEndPosition] = useState(null); // Set the scroll end position reference
  const [lastScrollTime, setLastScrollTime] = useState(0);
  const [relatedSharingVariant, setRelatedSharingVariant] = useState(null);
  const { experiments, getExperimentValue } = useContext(ABeagleContext);
  const { id } = useContext(BuzzContext);
  const scrollTimeoutRef = useRef(null);

  // set if user can natively share on mobile device
  useEffect(() => {
    if (isNativeShareEligible()) {
      setIsMobileShareEnabled(true);
    }
  }, []);

  // set if float shares button is currently displayed
  useEffect(() => {
    // Check here to verify a/b variant test segment
    const isValidVariant = relatedSharingVariant !== 'share_enabled_v1';
    setIsVisible((!isScrolling && isStartTriggered && isValidVariant && isMobileShareEnabled && !isEndTriggered));
  }, [isScrolling, isStartTriggered, isEndTriggered, relatedSharingVariant]);

  // get related sharing experiment variant
  useEffect(() => {
    if (experiments && experiments.loaded) {
      setRelatedSharingVariant(
        getExperimentValue('XX-1600-shopping-subbuzz-sharing', {
          rejectErrors: false,
        })
      );
    }
  }, [experiments, getExperimentValue]);

  //  handle hiding and showing of share triggered by scroll event
  useEffect(() => {
    const container = window;
    const handleTimeout = () => {
      clearTimeout(scrollTimeoutRef.current);
      scrollTimeoutRef.current = setTimeout(() => {
        setIsScrolling(false);
      }, 250); // show with 1/4th second delay
    };
    if (!isScrolling) { // always set scrolling to true, if false, when triggered
      setIsScrolling(true);
    }
    if (!isStartTriggered) { // check to see if user has scrolled down to start trigger point
      const _isStartTriggered = container.scrollTop > 20 || container.scrollY > 20 || container.pageYOffset > 20;
      setIsStartTriggered(_isStartTriggered);
    }
    if (endPosition) { // when end position is defined check scroll
      const contentOffset = 50;
      const scrollY = container.scrollTop || container.scrollY || container.pageYOffset;
      const _isEndTriggered = (scrollY - endPosition) > contentOffset;
      setIsEndTriggered(_isEndTriggered);
    }
    const debouceHandleTimeout = debounce(handleTimeout, 60);
    debouceHandleTimeout();
  }, [lastScrollTime]);

  useEffect(() => {
    const container = window;
    const checkScrollStatus = () => setLastScrollTime(Date.now());
    // bind scroll watch
    container.addEventListener('scroll', checkScrollStatus);
    return function cleanup() {
      container.removeEventListener('scroll', checkScrollStatus);
    };
  }, []);

  useEffect(() => {
    if (isFloatingShareButtonEnd) { // when the end point observable is triggered we record the scrollY position
      const container = window;
      const scrollY = container.scrollTop || container.scrollY || container.pageYOffset;
      setEndPosition(scrollY);
    }
  }, [isFloatingShareButtonEnd]);


  return (
    <div className={`${styles.floatingShareButton} ${className} 
      ${(isVisible) ? styles.visible : ''}
      ${(!isStartTriggered) ? styles.inactive : ''}`}>
     <NativeShareButton
        type='page_level'
        variant='icon_only'
        includeShortTitle
        trackingData={{
          unit_type: 'modal',
          unit_name: id,
          subunit_type: 'component',
          subunit_name: 'floating_share',
        }}
      />
    </div>
  );
};

FloatingShareButton.propTypes = {
  className: PropTypes.string,
  isFloatingShareButtonEnd: PropTypes.bool,
};

export default FloatingShareButton;
