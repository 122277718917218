import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { ReplyIcon, CheckmarkIcon } from '@buzzfeed/react-components';
import { share } from '../../../utils/sharing';
import { useTranslation } from '../../../i18n';
import BuzzContext from '../../../contexts/buzz';
import { trackClientContentAction } from '../../../hooks/analytics/client-event-tracking';
import styles from './copy-share-button.module.scss';

export const CopyShareButton = ({
  canonicalUrl,
  subbuzzId,
  /**
  * Tracking data specific to the location on the page the button is used
  *    Should always provide the following:
  *     unit_name
  *     unit_type
  *    Optionally can provide (will set to '' if not provided)
  *     subunit_name
  *     subunit_type
  **/
  trackingData = {},
}) => {
  const [hasClicked, setHasClicked] = useState(false);
  const { t } = useTranslation('common');
  const buzz = useContext(BuzzContext);

  const onClick = () => {
    setHasClicked(true);
    const data = {};
    if (subbuzzId) {
      data.subbuzzId = subbuzzId;
    }

    // compile tracking fields & trigger a CET event
    const trackingFields = {
      action_type: 'share',
      action_value: 'copy',
      item_type: 'button',
      item_name: 'copy',
      // then include position specific tracking data
      ...trackingData
    };
    trackClientContentAction(buzz, trackingFields);

    share('copy', { canonical_url: canonicalUrl }, data);
    setTimeout(() => {
      setHasClicked(false);
    }, 500);

  }
  return (
    <button
      className={styles.copyShareButton}
      onClick={onClick}
    >
      {hasClicked ?
        <CheckmarkIcon className={`${styles.buttonIcon} ${styles.shared}`} /> :
        <ReplyIcon className={`${styles.buttonIcon} ${styles.share}`}/>
      }
      <span className={styles.buttonText}>{hasClicked ? t('link_copied') : t('share')}</span>
    </button>
  );
}

CopyShareButton.propTypes = {
  canonicalUrl: PropTypes.string.isRequired,
  subbuzzId: PropTypes.string
}

export default CopyShareButton;
